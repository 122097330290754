import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formattingValues } from '../util'

export const LandingHero = ({ title, copy }) => {
  return (
    <section className="ciicc-landing-hero-section">
      <div className="ciicc-landing-hero">
        <div className="layout-grid -no-padding-sm">
          <div className="ciicc-landing-hero__content">
            <p className="text -bold heading -tertiary">
              <FormattedMessage id={title} />
            </p>
            <div className="ciicc-landing-hero__content__copy ciicc-rtf">
              <FormattedMessage id={copy} values={formattingValues} />
            </div>
          </div>
        </div>
        <p className="ciicc-landing-hero__image-caption">
          © Conservation International
        </p>
      </div>
    </section>
  )
}
