import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { IntlProvider, useIntl } from 'react-intl'
import ScrollToTop from './components/scroll-to-top'

export const LocalizedRouter = ({ children, strings, defaultLang }) => (
  <BrowserRouter>
    <ScrollToTop />
    <Route path="/:lang([a-z]{2}-[a-z]{2})">
      {({ match, location }) => {
        const lang = defaultLang || 'en-us'
        const supportedLang = strings.countries.find(c => c.value === lang)
          ? lang
          : defaultLang

        // If language is not in route path, redirect to language root
        const { pathname } = location
        if (!pathname.includes(`/${supportedLang}/`)) {
          return <Redirect to={`/${supportedLang}/`} />
        }

        return (
          <IntlProvider locale={lang} messages={strings[supportedLang]}>
            {children}
          </IntlProvider>
        )
      }}
    </Route>
  </BrowserRouter>
)

export const LocalizedSwitch = ({ children }) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Switch>
      {React.Children.map(children, child => {
        return child.type.name === Route.name
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.props.path)
            })
          : child
      })}
    </Switch>
  )

  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(path) {
    switch (typeof path) {
      case 'undefined':
        return undefined
      case 'object':
        return path.map(key => `/${locale}` + formatMessage({ id: key }))
      default:
        const isFallbackRoute = path === '*'
        return isFallbackRoute ? path : `/${locale}` + path
    }
  }
}
