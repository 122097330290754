import React from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash.groupby'

export const Select = ({
  onChange,
  id,
  localizedLabelText,
  options,
  value = '',
  tabIndex,
  isLabelHidden = true,
  wrapperClass = null,
  isGrouped = false
}) => {
  return (
    <div className={wrapperClass ? wrapperClass : ''}>
      <label className={isLabelHidden ? 'screenreader-only' : ''} htmlFor={id}>
        {localizedLabelText}
      </label>
      <select
        className="ciicc-select"
        id={id}
        value={value}
        onChange={onChange}
        tabIndex={tabIndex ?? 0}
      >
        {isGrouped ? renderGroupedOptions(options) : renderOptions(options)}
      </select>
    </div>
  )
}

const renderOptions = options => {
  return options.map((option, i) => (
    <option value={option.value} key={`option-${option.title}-${i}`}>
      {option.title}
    </option>
  ))
}

const renderGroupedOptions = options => {
  let groupedOpts = groupBy(options, 'continent')

  return Object.keys(groupedOpts).map(continent => (
    <optgroup label={continent} key={continent}>
      {groupedOpts[continent].map((option, i) => (
        <option value={option.value} key={`option-${option.title}-${i}`}>
          {option.title}
        </option>
      ))}
    </optgroup>
  ))
}

Select.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  localizedLabelText: PropTypes.object,
  options: PropTypes.array,
  isLabelHidden: PropTypes.bool,
  wrapperClass: PropTypes.string
}
