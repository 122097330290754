import React from 'react'
import { FormattedMessage } from 'react-intl'

import landingHow1 from '../assets/images/landing-how1.png'
import landingHow1_2x from '../assets/images/landing-how1@2x.png'
import landingHow1_3x from '../assets/images/landing-how1@3x.png'
import landingHow2 from '../assets/images/landing-how2.png'
import landingHow2_2x from '../assets/images/landing-how2@2x.png'
import landingHow2_3x from '../assets/images/landing-how2@3x.png'
import landingHow3 from '../assets/images/landing-how3.png'
import landingHow3_2x from '../assets/images/landing-how3@2x.png'
import landingHow3_3x from '../assets/images/landing-how3@3x.png'

export const LandingHow = () => {
  const generateHowItem = (id, img1x, img2x, img3x) => {
    return (
      <div className="ciicc-landing-how__item">
        <img
          srcSet={`${img3x} 3x, ${img2x} 2x, ${img1x} 1x`}
          src={img3x}
          alt=""
        />
        <p>
          <FormattedMessage id={`home.how.item${id}`} />
        </p>
      </div>
    )
  }

  return (
    <section className="ciicc-section">
      <div className="ciicc-section-heading">
        <div className="ciicc-wrap -xnarrow">
          <h2 className="heading -secondary text -bold-condensed -center">
            <FormattedMessage id="home.how.title" />
          </h2>
          <p>
            <FormattedMessage id="home.how.copy" />
          </p>
        </div>
      </div>
      <div className="ciicc-landing-how">
        {generateHowItem(1, landingHow1, landingHow1_2x, landingHow1_3x)}
        <div className="ciicc-landing-how__symbol" aria-hidden="true">
          +
        </div>
        {generateHowItem(2, landingHow2, landingHow2_2x, landingHow2_3x)}
        <div className="ciicc-landing-how__symbol" aria-hidden="true">
          =
        </div>
        {generateHowItem(3, landingHow3, landingHow3_2x, landingHow3_3x)}
      </div>
    </section>
  )
}
