import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { CALCULATOR_TYPE } from '../calculator/constants'

export const LandingCta = injectIntl(({ intl, setCalcType }) => {
  return (
    <section className="ciicc-landing-cta__section ciicc-section">
      <div className="ciicc-wrap -xnarrow">
        <div className="ciicc-section-heading">
          <h2 className="heading -primary text -bold-condensed -center">
            <FormattedMessage id="home.questionnaire.text" />
          </h2>
          <p className="centered-text">
            <FormattedMessage id="home.questionnaire.copy" />
          </p>
        </div>
      </div>
      <div className="ciicc-landing-cta">
        <div className="layout-grid">
          <div className="ciicc-wrap ciicc-landing-cta__cta text -center">
            <h2 className="text -bold">
              <FormattedMessage id="home.questionnaire.cta.title" />
            </h2>
            <Link
              to={{
                pathname: `/${intl.locale}/questionnaire`
              }}
              onClick={() => setCalcType(CALCULATOR_TYPE.individual)}
              className="ciicc-button -blue"
            >
              <FormattedMessage id="home.questionnaire.cta.select.option.individual" />
            </Link>

            <Link
              to={{
                pathname: `/${intl.locale}/questionnaire`
              }}
              onClick={() => setCalcType(CALCULATOR_TYPE.household)}
              className="ciicc-button -blue"
            >
              <FormattedMessage id="home.questionnaire.cta.select.option.household" />
            </Link>
          </div>
        </div>
        <p className="ciicc-landing-cta__image-caption">© Trond Larsen</p>
      </div>
    </section>
  )
})
