import React, { useState } from 'react'

import { BillingAddressForm } from './billing-address-form'
import { CardForm } from './card-form'
import { Header, Body } from '../shared/layout'
import { BackButton, StartOverButton } from '../shared/buttons'

export const CreditCard = ({ onPaymentError, onPaymentSuccess }) => {
  const [formStep, setFormStep] = useState('billingAddress')
  const [billingAddress, setBillingAddress] = useState({})

  const goBack = () => {
    setFormStep('billingAddress')
  }

  const handleAddressSubmit = e => {
    e.preventDefault()
    // serialize values to state
    const formData = new FormData(e.target)
    const addressEntries = [...formData.entries()].reduce(
      (memo, [key, value]) => {
        memo[key] = value
        return memo
      },
      {}
    )
    setBillingAddress(addressEntries)
    // set local state to advance to credit card form
    setFormStep('card')
  }

  return (
    <>
      <Header showDonation viewScreen={formStep}>
        {formStep === 'billingAddress' && <StartOverButton />}
        {formStep === 'card' && <BackButton onClick={goBack} />}
      </Header>
      <Body>
        {formStep === 'card' && (
          <CardForm
            billingAddress={billingAddress}
            onPaymentError={onPaymentError}
            onPaymentSuccess={onPaymentSuccess}
          />
        )}
        {formStep === 'billingAddress' && (
          <BillingAddressForm onSubmit={handleAddressSubmit} />
        )}
      </Body>
    </>
  )
}
