import React from 'react'
import { FormattedMessage } from 'react-intl'
import { REGIONAL_FACTORS } from '../calculator/constants'
import airTravelImg from '../assets/images/comparison-flying.png'
import airTravelImg2x from '../assets/images/comparison-flying@2x.png'
import airTravelImg3x from '../assets/images/comparison-flying@3x.png'
import householdImg from '../assets/images/comparison-housing.png'
import householdImg2x from '../assets/images/comparison-housing@2x.png'
import householdImg3x from '../assets/images/comparison-housing@3x.png'
import transportationImg from '../assets/images/comparison-transportation.png'
import transportationImg2x from '../assets/images/comparison-transportation@2x.png'
import transportationImg3x from '../assets/images/comparison-transportation@3x.png'
import { formattingValues } from '../util'

export const ResultsComparisonGraphs = ({
  airTravel,
  transportation,
  householdAndDiet,
  region,
  type
}) => {
  const generateCharts = (user, average, imageKey, imageKey2x, imageKey3x) => {
    const isAverageLarger = user / average > 1

    return (
      <>
        <div className="layout-grid -no-gap -no-padding-sm">
          <div className="ciicc-results-comparison__graph-label">
            <img
              srcSet={`${imageKey3x} 3x, ${imageKey2x} 2x`}
              src={imageKey}
              alt=""
            />
            <p className="text -bold">
              <FormattedMessage id="results.comparison.you" />
            </p>
          </div>
          <div className="ciicc-results-comparison__graph__container">
            <p className="screenreader-only">{user}</p>
            <div
              className="ciicc-results-comparison__graph -user"
              style={{
                width: isAverageLarger ? '100%' : `${(user / average) * 100}%`
              }}
            ></div>
            <p className="text -bold">
              <FormattedMessage
                id="results.comparison.emissions"
                values={{
                  ...formattingValues,
                  value: user
                }}
              />
            </p>
          </div>
        </div>
        <div className="layout-grid -no-gap -no-padding-sm">
          <p className="ciicc-results-comparison__graph-label">
            <FormattedMessage id={`results.comparison.average.${region}`} />
          </p>
          <div className="ciicc-results-comparison__graph__container">
            <p className="screenreader-only">{average}</p>
            <div
              className="ciicc-results-comparison__graph -average"
              style={{
                width: isAverageLarger ? `${(average / user) * 100}%` : '100%'
              }}
            ></div>
            <p>
              <FormattedMessage
                id="results.comparison.emissions"
                values={{
                  ...formattingValues,
                  value: average
                }}
              />
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="ciicc-results-comparison__graphs">
      <div className="ciicc-results-comparison__graphs__container -household">
        <h3 className="screenreader-only">
          <FormattedMessage id="results.comparison.household" />
        </h3>
        {generateCharts(
          householdAndDiet,
          REGIONAL_FACTORS[region].average.housingAndDiet[type],
          householdImg,
          householdImg2x,
          householdImg3x
        )}
      </div>
      <div className="ciicc-results-comparison__graphs__container -transportation">
        <h3 className="screenreader-only">
          <FormattedMessage id="results.comparison.transportation" />
        </h3>
        {generateCharts(
          transportation,
          REGIONAL_FACTORS[region].average.groundTransportation[type],
          transportationImg,
          transportationImg2x,
          transportationImg3x
        )}
      </div>
      <div className="ciicc-results-comparison__graphs__container -air">
        <h3 className="screenreader-only">
          <FormattedMessage id="results.comparison.travel" />
        </h3>
        {generateCharts(
          airTravel,
          REGIONAL_FACTORS[region].average.airTravel[type],
          airTravelImg,
          airTravelImg2x,
          airTravelImg3x
        )}
      </div>
    </div>
  )
}
