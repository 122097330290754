const PREFIX = 'ciicc'

/**
 * Super light localStorage wrapper.
 */
export class Storage {
  static get = (key, defaultValue = null) => {
    let value = localStorage.getItem(`${PREFIX}.${key}`)

    if (!value) {
      return defaultValue
    }

    return value
  }

  static getParsedJSON = key => {
    let value = localStorage.getItem(`${PREFIX}.${key}`)
    let parsed = JSON.parse(value)

    return value ? parsed : null
  }

  static set = (key, newValue) => {
    localStorage.setItem(`${PREFIX}.${key}`, newValue)

    return newValue
  }

  static delete = key => {
    localStorage.removeItem(`${PREFIX}.${key}`)
  }
}
