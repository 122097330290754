import React from 'react'
import Modal from 'react-modal'

import { ModalErrorBoundary } from './modal-error-boundary'
import { UnrestrictedForm } from './unrestricted-form'
import { RestrictedForm } from './restricted-form'
import { CreditCard } from './credit-card'
import { ErrorMessage } from './shared/error-message'
import { SuccessEmailSignup } from './shared/success-email-signup'

import { useModal } from '../../hooks/use-modal'
import { usePayment } from '../../hooks/use-payment'
import { usePaymentRequest } from '../../hooks/use-payment-request'

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const postPaymentComponents = {
  error: ErrorMessage,
  success: SuccessEmailSignup
}

const paymentTypeComponents = {
  applePay: () => null, // currently unused - payment request happens in a browser popup
  creditCard: CreditCard
}

export const DonationModal = () => {
  const {
    isOpen,
    actions: { closeModal }
  } = useModal()

  const {
    restricted,
    paymentType,
    postPaymentStep,
    postPaymentData,
    locale,
    userLocation,
    actions: { reset: resetPayment, setPostPayment }
  } = usePayment()

  const { supportsApplePay } = usePaymentRequest()

  const handleModalClose = () => {
    closeModal()
    resetPayment()
  }

  const InitialComponent = restricted ? RestrictedForm : UnrestrictedForm
  const PaymentComponent = postPaymentStep
    ? postPaymentComponents[postPaymentStep]
    : paymentTypeComponents[paymentType]
  const shouldShowInitial =
    paymentType === null ||
    (paymentType === 'applePay' && postPaymentStep == null)
  const Component = shouldShowInitial ? InitialComponent : PaymentComponent

  function handlePaymentError(response) {
    let data = response
    // normalize for different shape from https://stripe.com/docs/error-codes/payment-intent-authentication-failure
    if (
      response.error &&
      typeof response.error === 'object' &&
      response.error.message
    ) {
      data = {
        error: response.error.message
      }
    }
    setPostPayment({ step: 'error', data })
  }

  function handlePaymentSuccess() {
    setPostPayment({ step: 'success' })
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={handleModalClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="ciicc-modal-overlay"
      className="ciicc-modal"
    >
      <ModalErrorBoundary locale={locale} userLocation={userLocation}>
        <Component
          onPaymentError={handlePaymentError}
          onPaymentSuccess={handlePaymentSuccess}
          postPaymentData={postPaymentData}
          supportsApplePay={supportsApplePay}
        />
      </ModalErrorBoundary>
    </Modal>
  )
}
