import { REGIONAL_FACTORS } from '../calculator/constants'

export const TARGET_JURISDICTIONS = new Set(Object.keys(REGIONAL_FACTORS))

/**
 * Country codes from db-ip to language mapping
 * http://www.lingoes.net/en/translator/langcode.htm
 */
export const COUNTRY_CODE_TO_LANG = {
  // Country
  US: 'en-us',
  CA: 'en-us',
  MX: 'es-la',
  GB: 'en-gb',
  FR: 'fr-fr',
  PT: 'pt-br',
  DE: 'de-de',
  ES: 'es-es',
  BR: 'pt-br',
  AR: 'es-la',
  CO: 'es-la',
  KR: 'ko-kr',
  IN: 'en-gb',
  JP: 'ja-jp',
  SG: 'en-gb',
  AU: 'en-gb',
  NZ: 'en-gb',

  // Blacklisted Countries Language Mapping
  CN: 'en-us', // China
  MO: 'en-us', // Macau
  VE: 'es-la', // Venezuela
  ID: 'en-us', // Indonesia
  MY: 'en-us', // Malaysia
  RU: 'en-us', // Russia
  CU: 'en-us', // Cuba
  IR: 'en-us', // Iran
  KP: 'en-us', // North Korea
  SD: 'en-us', // Sudan
  SY: 'en-us', // Syria
  UA: 'en-us' // Ukraine Crimean region
}

export const CONTINENT_CODE_TO_LANG = {
  // Default International
  INTL: 'en-us',
  NA: 'en-us',
  EU: 'en-gb',
  AN: 'en-us',
  AF: 'en-us',
  AS: 'en-us',
  AU: 'en-gb',
  SA: 'es-la'
}

export const BLACKLIST = new Set([
  'CN', // China
  'MO', // Macau
  'VE', // Venezuela
  'ID', // Indonesia
  'IN', // India
  'MY', // Malaysia
  'RU', // Russia
  'CU', // Cuba
  'IR', // Iran
  'KP', // North Korea
  'SD', // Sudan
  'SY', // Syria
  'UA' // Ukraine Crimean region
])

/**
 * Mapping of country code to currency.
 */
export const CURRENCY_MAP = {
  AR: { title: 'ARS', symbol: '$' },
  AU: { title: 'AUD', symbol: '$' },
  BR: { title: 'BRL', symbol: 'R$' },
  CA: { title: 'CAD', symbol: '$' },
  CO: { title: 'COP', symbol: '$' },
  EU: { title: 'EUR', symbol: '€' },
  GB: { title: 'GBP', symbol: '£' },
  JP: { title: 'JPY', symbol: '¥' },
  KR: { title: 'KRW', symbol: '₩' },
  MX: { title: 'MXN', symbol: '$' },
  SG: { title: 'SGD', symbol: '$' },
  US: { title: 'USD', symbol: '$' },
  NZ: { title: 'NZD', symbol: '$' }
}

/**
 * Preset donation denominations that are user-friendly
 * to the specific currency.
 */
export const DONATION_AMOUNTS = {
  ARS: [400, 800, 1200],
  AUD: [15, 70, 105],
  BRL: [35, 100, 150],
  CAD: [10, 60, 95],
  COP: [25000, 65000, 100000],
  EUR: {
    DE: [10, 40, 55],
    ES: [5, 35, 50],
    FR: [10, 40, 60],
    PT: [5, 35, 50],
    EU: [5, 35, 55]
  },
  GBP: [5, 35, 55],
  JPY: [1000, 5100, 7600],
  KRW: [10000, 45000, 65000],
  MXN: [150, 475, 700],
  SGD: [10, 45, 65],
  USD: [25, 50, 75],
  NZD: [15, 75, 110]
}

/**
 * IANA registry codes for locales
 */
export const REGISTRIES = {
  'en-us': 'en-US',
  'en-gb': 'en-GB',
  'es-es': 'es-ES',
  'es-la': 'es-419',
  'fr-fr': 'fr-FR',
  'pt-br': 'pt-BR',
  'de-de': 'de-DE',
  'ko-kr': 'ko-KR',
  'ja-jp': 'ja-JP'
}
