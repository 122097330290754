import { BLACKLIST, TARGET_JURISDICTIONS } from './constants/countries'
import { getLanguageForLocation } from './util'

export const l10nActions = {
  SET_REGION: 'SET_REGION',
  SET_LANGUAGE: 'SET_LANGUAGE'
}

export const l10nReducer = (state, action) => {
  switch (action.type) {
    case l10nActions.SET_REGION:
      const { location } = action.payload
      let { countryCode, continentCode } = location

      // If the country isn't a targeted region, overwrite with the continent
      // it belongs to. We don't want to overwrite the countryCode for
      // blacklisted countries!
      if (
        !TARGET_JURISDICTIONS.has(countryCode) &&
        !BLACKLIST.has(countryCode)
      ) {
        countryCode = continentCode
      }

      // If we don't have a valid language mapping, default to the continent
      const language = getLanguageForLocation(location)

      return {
        ...state,
        countryCode,
        continentCode,
        language
      }

    case l10nActions.SET_LANGUAGE:
      const { code } = action.payload

      return {
        ...state,
        language: code
      }

    default:
      return state
  }
}
