/**
 * Calculator Data Table
 * India
 */
export const INDIA = {
  continent: 'AS',
  energy: {
    A: 0.01,
    B: 0.22,
    C: 0.63,
    D: 0.83,
    E: 0.81
  },
  diet: {
    A: 0.61,
    B: 0.468,
    C: 0.312,
    D: 0.312,
    E: 0.26
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 1,
  average: {
    annualFootprint: {
      individual: 1.84,
      household: 7.37
    },
    housingAndDiet: {
      individual: 0.32,
      household: 1.45
    },
    groundTransportation: {
      individual: 0.03,
      household: 0.1
    },
    airTravel: {
      individual: 0.02,
      household: 0.03
    }
  }
}
