import React, { useState } from 'react'
import { submitToLuminate } from '../services/luminate-client'

export const useLuminate = (initialState = null) => {
  const [data, setData] = useState(initialState)
  const [response, setResponse] = useState({ success: false })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  React.useEffect(() => {
    const postData = async () => {
      setLoading(true)

      try {
        const response = await submitToLuminate(data)

        if (response.data.statusCode === 200) {
          setResponse({ success: true })
        } else {
          setError({
            message: 'Something went wrong.'
          })
        }
      } catch (error) {
        console.log(error)

        setResponse({ success: false })
        setError({
          message: error.message
        })
      }

      setLoading(false)
    }

    if (data) {
      postData()
    }
  }, [data])

  return [{ response, loading, error }, setData]
}
