import React, { useEffect, useReducer } from 'react'
import { Route } from 'react-router-dom'
import { LocalizedRouter, LocalizedSwitch } from './router'
import { getUserLocation } from './services/location'
import { Storage } from './services/storage'
import { DEFAULT_FACTORS, CALCULATOR_TYPE } from './calculator/constants'
import { CalculatorContext } from './calculator/context'
import { HouseholdCalculator } from './calculator/household'
import { IndividualCalculator } from './calculator/individual'
import { LoadingView } from './components/loading-view'
import { DonationModal } from './components/donate/modal'
import { DonationModalProvider } from './components/donate/modal-container'

import { CONFIG } from './config/index'

import { SkipToLink } from './components/skip-to-link'
import { Header } from './components/header'
import { Page404 } from './components/page-404'
import { l10nActions, l10nReducer } from './reducers'
import {
  getLanguageFromUrl,
  getLanguageForLocation,
  getLocationFromParams
} from './util'
import Landing from './pages/landing'
import Questionnaire from './pages/questionnaire'
import Results from './pages/results'
import ThreeDSecureComplete from './pages/three-d-secure-complete'
import strings from './constants/strings'
import { REGISTRIES } from './constants/countries'
import './assets/styles/app.scss'

import { makeServer } from './mirage/server'
import { Analytics } from './components/analytics'
import { ShareMeta } from './components/meta/share'
import { sendToDataLayer } from './util/index'

if (process.env.NODE_ENV === 'development') {
  makeServer({ environment: 'development' })
}

export default function AppContainer() {
  const { search } = window.location

  // We try to fetch location either via local storage, or from the URL param.
  // If both of those fail, we try to infer location using the language.
  const initialLocation =
    Storage.getParsedJSON('userLocation') ??
    getLocationFromParams(search, getLanguageFromUrl())

  const [{ language, ...userLocation }, dispatchLocale] = useReducer(
    l10nReducer,
    {
      language: getLanguageFromUrl() ?? getLanguageForLocation(initialLocation),
      ...initialLocation
    }
  )

  const isLocalized =
    userLocation.countryCode !== undefined && language !== undefined

  const onRegionChange = location =>
    dispatchLocale({ type: l10nActions.SET_REGION, payload: { location } })

  const onLanguageChange = code =>
    dispatchLocale({ type: l10nActions.SET_LANGUAGE, payload: { code } })

  useEffect(() => {
    const fetchLocation = async () => {
      const { countryCode, continentCode } = await getUserLocation()

      onRegionChange({ countryCode, continentCode })
    }

    if (!isLocalized) {
      fetchLocation()
    }

    // sets lang attribute to match user's language
    document.documentElement.setAttribute('lang', REGISTRIES[language])

    // All location settings should be persisted to localStorage
    Storage.set('userLocation', JSON.stringify(userLocation))
  }, [isLocalized, userLocation])

  return isLocalized ? (
    <App
      userLocation={userLocation}
      onRegionChange={onRegionChange}
      onLangChange={onLanguageChange}
      locale={language}
    />
  ) : (
    <LoadingView />
  )
}

function App({ locale, userLocation, onRegionChange, onLangChange }) {
  const { countryCode } = userLocation
  const [factors, setFactors] = React.useState(DEFAULT_FACTORS)
  const [calculator, setCalculator] = React.useState(
    new IndividualCalculator(userLocation)
  )
  const [
    isHeaderDonateButtonVisible,
    setHeaderDonateButtonVisibility
  ] = React.useState(true)

  useEffect(() => {
    sendToDataLayer(locale, userLocation, '1 - Calculator Loaded')
  }, [locale, userLocation])

  useEffect(() => {
    setCalculator(new IndividualCalculator(userLocation))
  }, [JSON.stringify(userLocation)])

  const setCalcType = userCalculator => {
    let isIndividual = userCalculator === CALCULATOR_TYPE.individual

    setCalculator(
      isIndividual
        ? new IndividualCalculator(userLocation)
        : new HouseholdCalculator(userLocation)
    )
  }

  return (
    <CalculatorContext.Provider
      value={{
        calculator,
        factors,
        locale,
        userLocation
      }}
    >
      <LocalizedRouter strings={strings} defaultLang={locale}>
        <Analytics />
        <DonationModalProvider locale={locale} userLocation={userLocation}>
          <div id="app">
            <SkipToLink />
            <Header
              locale={locale}
              countryCode={countryCode}
              onRegionChange={onRegionChange}
              onLangChange={onLangChange}
              isDonateButtonVisible={isHeaderDonateButtonVisible}
            />

            <LocalizedSwitch>
              <Route
                exact
                path="/questionnaire"
                render={({ location, history }) => {
                  setHeaderDonateButtonVisibility(
                    CONFIG.QUESTIONNAIRE_DONATE_BUTTON
                  )
                  return (
                    <>
                      <ShareMeta pageKey="questionnaire" locale={locale} />
                      <Questionnaire
                        location={location}
                        history={history}
                        setFactors={setFactors}
                      />
                    </>
                  )
                }}
              />

              <Route
                exact
                path="/results"
                render={props => {
                  setHeaderDonateButtonVisibility(CONFIG.RESULTS_DONATE_BUTTON)
                  return (
                    <>
                      <ShareMeta pageKey="results" locale={locale} />
                      <Results
                        {...props}
                        userLocation={userLocation}
                        locale={locale}
                      />
                    </>
                  )
                }}
              />

              <Route
                exact
                path="/3ds-complete"
                render={() => <ThreeDSecureComplete />}
              />

              <Route
                exact
                path="/"
                render={() => {
                  setHeaderDonateButtonVisibility(CONFIG.LANDING_DONATE_BUTTON)
                  return (
                    <>
                      <ShareMeta pageKey="landing" locale={locale} />
                      <Landing setCalcType={setCalcType} />
                    </>
                  )
                }}
              />

              <Route render={() => <Page404 />} />
            </LocalizedSwitch>
            <DonationModal />
          </div>
        </DonationModalProvider>
      </LocalizedRouter>
    </CalculatorContext.Provider>
  )
}
