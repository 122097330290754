import React from 'react'
import { Link } from 'react-router-dom'

export const Page404 = () => (
  <section className="ciicc-section">
    <h1 className="page-404__heading">
      <span className="heading -primary text -bold-condensed">404</span>
      <span className="heading -tertiary text -bold">Page Not Found</span>
    </h1>
    <p className="centered-text">
      Please click the link below and calculate your carbon footprint.
    </p>
    <Link to={`/`} className="page404__link ciicc-button -blue">
      Go To Calculator
    </Link>
    <div className="ciicc-wrap">
      <div className="page404__translations">
        <div className="page404__translation centered-text">
          <h2 className="heading -tertiary text -bold">Página no encontrada</h2>
          <p>
            Haga clic en el siguiente enlace y calcule su huella de carbono.
          </p>
          <Link to={`/`} className="text -bold">
            Ir a la calculadora
          </Link>
        </div>
        <div className="page404__translation centered-text">
          <h2 className="heading -tertiary text -bold">Page non trouvée</h2>
          <p>
            Veuillez cliquer sur le lien ci-dessous et calculer votre empreinte
            carbone.
          </p>
          <Link to={`/`} className="text -bold">
            Accéder à la calculatrice
          </Link>
        </div>
        <div className="page404__translation centered-text">
          <h2 className="heading -tertiary text -bold">Seite nicht gefunden</h2>
          <p>
            Bitte klicken Sie auf den unten stehenden Link und berechnen Sie
            Ihren CO2-Fußabdruck.
          </p>
          <Link to={`/`} className="text -bold">
            Zum Rechner gehen
          </Link>
        </div>
        <div className="page404__translation centered-text">
          <h2 className="heading -tertiary text -bold">
            Página não encontrada
          </h2>
          <p>Clique no link abaixo e calcule sua pegada de carbono.</p>
          <Link to={`/`} className="text -bold">
            Vá para a calculadora
          </Link>
        </div>
        <div className="page404__translation centered-text">
          <h2 className="heading -tertiary text -bold">
            ページが見つかりません
          </h2>
          <p>
            以下のリンクをクリックして、二酸化炭素排出量を計算してください。
          </p>
          <Link to={`/`} className="text -bold">
            電卓に行く
          </Link>
        </div>
        <div className="page404__translation centered-text">
          <h2 className="heading -tertiary text -bold">
            페이지를 찾을 수 없습니다
          </h2>
          <p>아래 링크를 클릭하고 탄소 발자국을 계산하십시오.</p>
          <Link to={`/`} className="text -bold">
            계산기로 이동
          </Link>
        </div>
      </div>
    </div>
  </section>
)
