/**
 * Calculator Data Table
 * Britain
 */
export const BRITAIN = {
  continent: 'EU',
  energy: {
    A: 0.07,
    B: 0.75,
    C: 2.12,
    D: 2.8,
    E: 2.31
  },
  diet: {
    A: 1.76,
    B: 1.36,
    C: 1.08,
    D: 0.71,
    E: 0.4
  },
  transit: {
    above: 0.00004,
    below: 0.00003,
    bus: 0.00008
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 3,
  average: {
    annualFootprint: {
      individual: 5.81,
      household: 23.25
    },
    housingAndDiet: {
      individual: 1.92,
      household: 4.41
    },
    groundTransportation: {
      individual: 1.64,
      household: 2.83
    },
    airTravel: {
      individual: 0.42,
      household: 0.76
    }
  }
}
