import React, { useState, useEffect, useMemo } from 'react'
import queryString from 'query-string'
import isEmpty from 'lodash.isempty'
import {
  COUNTRY_CODE_TO_LANG,
  CONTINENT_CODE_TO_LANG,
  CURRENCY_MAP,
  DONATION_AMOUNTS
} from '../constants/countries'
import { CONFIG } from '../config'
import { REGIONAL_FACTORS } from '../calculator/constants'

const multipleChoiceOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

export const generateSelectOptions = options => {
  if (Array.isArray(options)) {
    return options.map((option, index) => ({
      title: option.title ? option.title : option,
      value:
        option.value !== undefined ? option.value : multipleChoiceOptions[index]
    }))
  }

  throw new Error(
    'Options must be an array of title and value objects or an array of strings.'
  )
}
export const round = (num, digits = 2) => {
  return parseFloat(num.toFixed(digits))
}

export const formattingValues = {
  a: (...chunks) => (
    <a href={chunks[0]} target="_blank" rel="noopener noreferrer">
      {chunks[2]}
    </a>
  ),
  b: (...chunks) => <b>{chunks}</b>,
  br: <br />,
  fragment: <></>,
  h3: (...chunks) => <h3>{chunks}</h3>,
  li: (...chunks) => <li>{chunks}</li>,
  p: (...chunks) => <p>{chunks}</p>,
  sub: (...chunks) => <sub>{chunks}</sub>,
  ul: (...chunks) => <ul>{chunks}</ul>
}

export const calcHectares = carbonImpact => {
  return round(carbonImpact / 2.75)
}

export const useMedia = query => {
  const mediaQuery = useMemo(() => matchMedia(query), [query])

  const match = React.useCallback(() => {
    return mediaQuery.matches
  }, [mediaQuery])

  const [value, setValue] = useState(match)

  useEffect(() => {
    const handleResize = () => {
      setValue(match())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [match, value])

  return value
}

/**
 * Returns a language code from the URL, if it exists.
 * @returns {String} ie 'en-us'
 */
export const getLanguageFromUrl = (url = window.location.pathname) => {
  const regex = /[a-z]{2}-[a-z]{2}/gi
  const langCodeMatches = regex.exec(url) ?? []

  return langCodeMatches.length > 0
    ? langCodeMatches[0].toLowerCase()
    : undefined
}

/**
 * Returns a language code for a given countryCode and continentCode.
 * @returns {String} ie 'en-us'
 */
export const getLanguageForLocation = location => {
  if (!location) {
    return 'en-us'
  }

  return (
    COUNTRY_CODE_TO_LANG[location.countryCode] ??
    CONTINENT_CODE_TO_LANG[location.continentCode]
  )
}

/**
 * Returns a designated currency symbol given a country and continent.
 * @param {Object} userLocation
 */
export const getTargetCurrency = ({ countryCode, continentCode }) => {
  let targetCurrency = CURRENCY_MAP[countryCode]
    ? CURRENCY_MAP[countryCode].title
    : null

  // If the currency for the country isn't supported, we'll fall back
  // to either EUR or USD depending on the continent.
  if (!targetCurrency) {
    switch (continentCode) {
      case 'EU':
        targetCurrency = 'EUR'
        break
      case 'NA':
        targetCurrency = 'USD'
        break

      default:
        targetCurrency = 'USD'
        break
    }
  }

  return targetCurrency
}

/**
 * Returns a formatted currency string specific to the language/location.
 * @param {Int} amount in USD
 * @param {String} locale
 * @param {Object} userLocation
 */
export const formatCurrency = (
  amount,
  locale,
  userLocation,
  customCurrency = null
) => {
  return new Intl.NumberFormat(locale, {
    currency: customCurrency ?? getTargetCurrency(userLocation),
    style: 'currency'
  }).format(amount)
}

/**
 * Returns the price value of a form as a string
 * @param {Object} formRef
 */
export const determineFormValue = formRef => {
  const inputs = formRef.current.querySelectorAll('input')
  let value = null

  for (let input of inputs) {
    const valueMeetsMinimum =
      parseFloat(input.value) >= parseFloat(CONFIG.DONATION_MINIMUM)
    const isInputSelected = input.checked || input.type !== 'radio'

    if (valueMeetsMinimum && isInputSelected) {
      value = input.value
      break
    }
  }

  return value
}

/**
 * Returns a new path replacing the current language code with a new one.
 * @param {String} path
 * @param {String} code language code i.e: en-us
 */
export const getNewPathWithLang = (path, code) => {
  const pathWithoutLang = removeLangFromPath(path)
  const finalPath = `/${code}/${pathWithoutLang}`

  return finalPath
}

/**
 * Returns a new path without the language code.
 * @param {String} path (/en-us/results)
 */
export const removeLangFromPath = path => {
  const regex = /(\/[a-z]{2}-[a-z]{2}\/)/gi
  const pathWithoutLang = path.replace(regex, '')

  return pathWithoutLang
}

/**
 * Returns a location object given path params.
 */
export const getLocationFromParams = (searchParams, fallbackLang) => {
  const deserialized = queryString.parse(searchParams)
  const { r: countryCode } = deserialized
  

  if (isCrawler()) {
    const fallbackCountryCode = Object.keys(COUNTRY_CODE_TO_LANG).find(
      k => COUNTRY_CODE_TO_LANG[k] === fallbackLang
    )

    const fallbackContinentCode =
      REGIONAL_FACTORS[fallbackCountryCode].continent

    return {
      countryCode: fallbackCountryCode,
      continentCode: fallbackContinentCode
    }
  }

  if (isEmpty(deserialized)) {
    return {
      countryCode: undefined,
      continentCode: undefined
    }
  } else {
    if(!validateCountryCode(Object.getOwnPropertyNames(deserialized)[0]))
    {
      return {
        countryCode: undefined,
        continentCode: undefined
      }
    }
    else
    {
      return {
        countryCode,
        continentCode: REGIONAL_FACTORS[countryCode].continent
      }
    }
    

    
  }
}

/**
 * Returns whether the region uses Imperial system. Aside from
 * the US.
 * @param {String} countryCode ie: US
 */
export const getMeasurementSystem = countryCode => {
  return countryCode === 'US' ? 'imperial' : 'metric'
}

/**
 * Push to DataLayer
 * @param {String} locale global user langauge identifier
 * @param {Object} userLocation global user location identifier which includes
 * country and continent
 * @param {String} label ie: 'CI-specified-label'
 * @param {String} action ie: 'CI-specified-action'
 * @param {String} event defaults to 'InternationalCarbonCalculator'
 */
export const sendToDataLayer = (
  locale,
  userLocation,
  action,
  label = '',
  event = 'InternationalCarbonCalculator'
) => {
  if (typeof dataLayer !== 'undefined') {
    label = label.length > 0 ? `${label} - ` : label
    const dataLayer = (window.dataLayer = window.dataLayer || [])

    dataLayer.push({
      action: action,
      label: `${label}${locale} - ${
        userLocation.countryCode
      } - ${getTargetCurrency(userLocation)}`,
      event: event
    })
  }
}

export const isMinimum = (amount, rate) => {
  return amount / rate >= CONFIG.DONATION_MINIMUM
}

export const getDonationOptions = (currency, countryCode) => {
  let options = DONATION_AMOUNTS[currency]
  if (currency === 'EUR') {
    options = options[countryCode]
  }

  return options
}

export const isRussia = countryCode => {
  return countryCode === 'RU'
}

/**
 * Detects if a bot/crawler.
 */
function isCrawler() {
  const regex = /bot|crawler|spider|crawling|prerender/i
  return regex.test(navigator.userAgent)
}

function validateCountryCode(code)
{

  if(CONTINENT_CODE_TO_LANG[code])
  {
    return true;
  }
  else
  {
    return false;
  }

}