import { FRANCE } from './france'
import { PORTUGAL } from './portugal'
import { BRITAIN } from './britain'
import { GERMANY } from './germany'
import { SPAIN } from './spain'

/**
 * Energy emissions and diet factors for Europe.
 */
export const EUROPE = {
  name: 'EU',
  energy: {
    A: 0.07,
    B: 0.83,
    C: 2.36,
    D: 3.12,
    E: 2.21
  },
  diet: {
    A: 2.62,
    B: 2.05,
    C: 1.7,
    D: 1.32,
    E: 1.05
  },
  transit: {
    above: 0.000028,
    below: 0.00003,
    bus: 0.00024
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 3,
  average: {
    annualFootprint: {
      individual: 6.67,
      household: 26.66
    },
    housingAndDiet: {
      individual: 2.36,
      household: 5.66
    },
    groundTransportation: {
      individual: 1.33,
      household: 2.39
    },
    airTravel: {
      individual: 0.29,
      household: 0.81
    }
  },
  countries: { FRANCE, PORTUGAL, BRITAIN, GERMANY, SPAIN }
}
