/**
 * Calculator Data Table
 * South Korea
 */
export const SOUTH_KOREA = {
  continent: 'AS',
  energy: {
    A: 0.02,
    B: 0.74,
    C: 2.19,
    D: 2.91,
    E: 2.54
  },
  diet: {
    A: 3.04,
    B: 2.34,
    C: 1.86,
    D: 1.23,
    E: 0.68
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 4,
  average: {
    annualFootprint: {
      individual: 12.09,
      household: 48.34
    },
    housingAndDiet: {
      individual: 2.66,
      household: 6.66
    },
    groundTransportation: {
      individual: 0.6,
      household: 1.13
    },
    airTravel: {
      individual: 0.26,
      household: 0.47
    }
  }
}
