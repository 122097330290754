import { BaseCalculator } from './base'
import { CALCULATOR_TYPE, UTILITY_FACTORS, REGIONAL_FACTORS } from './constants'
import { round } from '../util'

/**
 * This Calculator object facilitates all carbon calculates for a given individual.
 */
export class IndividualCalculator extends BaseCalculator {
  constructor(userLocation) {
    super(userLocation)

    this.type = CALCULATOR_TYPE.individual
  }

  /**
   * (Q1 TYPE FACTOR * Q2 SIZE FACTOR * Q3 ENERGY EMISSIONS * Q4 RESIDENTS FACTOR) + Q5 DIET
   * @param {Object} homeFactors (see `DEFAULT_HOUSEHOLD_FACTORS`)
   */
  homeFootprint({ qType, qSize, qEmissions, qResidents, qDiet }) {
    const { region } = this

    const typeFactor = UTILITY_FACTORS.type[qType]
    const sizeFactor = UTILITY_FACTORS.size[qSize]
    const energyFactor = REGIONAL_FACTORS[region].energy[qEmissions]
    const residents =
      qResidents > 6
        ? UTILITY_FACTORS.residents[6]
        : UTILITY_FACTORS.residents[qResidents]
    const diet = REGIONAL_FACTORS[region].diet[qDiet]

    return round(typeFactor * sizeFactor * energyFactor * residents + diet)
  }
}
