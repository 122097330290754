/**
 * Calculator Data Table
 * Germany
 */
export const GERMANY = {
  continent: 'EU',
  energy: {
    A: 0.06,
    B: 0.72,
    C: 2.05,
    D: 2.71,
    E: 1.91
  },
  diet: {
    A: 2.7,
    B: 2.07,
    C: 1.65,
    D: 1.09,
    E: 0.61
  },
  transit: {
    above: 0.000028,
    below: 0.00003,
    bus: 0.00024
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 3,
  average: {
    annualFootprint: {
      individual: 9.74,
      household: 38.94
    },
    housingAndDiet: {
      individual: 2.38,
      household: 4.99
    },
    groundTransportation: {
      individual: 1.42,
      household: 2.24
    },
    airTravel: {
      individual: 0.29,
      household: 0.53
    }
  }
}
