import { useContext } from 'react'

import { DonationModalContext } from '../components/donate/modal-container'

export const useModal = () => {
  const [state, dispatch] = useContext(DonationModalContext)
  const openModal = () =>
    dispatch({
      type: 'OPEN_MODAL'
    })
  const closeModal = () =>
    dispatch({
      type: 'CLOSE_MODAL'
    })

  return {
    ...state,
    actions: {
      openModal,
      closeModal
    }
  }
}
