/**
 * Calculator Data Table
 * Mexico
 */
export const MEXICO = {
  continent: 'NA',
  energy: {
    A: 0.03,
    B: 0.32,
    C: 0.91,
    D: 1.2,
    E: 1.12
  },
  diet: {
    A: 1.43,
    B: 1.1,
    C: 0.88,
    D: 0.58,
    E: 0.32
  },
  transit: {
    above: 0.000087,
    below: 0.000075,
    bus: 0.000035
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 2,
  average: {
    annualFootprint: {
      individual: 3.8,
      household: 15.18
    },
    housingAndDiet: {
      individual: 1.34,
      household: 4.97
    },
    groundTransportation: {
      individual: 0.8,
      household: 2.22
    },
    airTravel: {
      individual: 0.08,
      household: 0.14
    }
  }
}
