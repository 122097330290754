import React from 'react'

// https://stripe.com/docs/payments/3d-secure#handle-redirect
const ThreeDSecureComplete = () => {
  React.useEffect(() => {
    console.log('post message to top!')
    window.top.postMessage('3DS-authentication-complete')
  }, [])

  return <p>3D Secure Complete. Loading....</p>
}

export default ThreeDSecureComplete
