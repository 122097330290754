import axios from 'axios'

const isDevelopmentMode = process.env.NODE_ENV === 'development'
const apiKey = process.env.REACT_APP_IP_DB_API_KEY

const baseUrl = isDevelopmentMode
  ? 'http://api.db-ip.com/v2'
  : 'https://api.db-ip.com/v2'

const dbipClient = axios.create({
  baseURL: `${baseUrl}/${apiKey}/self`
})

/**
 * Fetches the user's location from db-ip using their IP address.
 * @returns {String} countryCode, or EN if it fails.
 */
export const getUserLocation = async () => {
  const fallback = { countryCode: 'US', continentCode: 'INTL' }

  try {
    const response = await dbipClient.get()

    if (response.data.error) {
      return fallback
    } else {
      return response.data
    }
  } catch (error) {
    return fallback
  }
}
