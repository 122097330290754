import React, { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'

import { Header, Body } from './layout'
import { StartOverButton } from './buttons'
import { DonorboxButton } from '../../donate-button'

import { usePayment } from '../../../hooks/use-payment'
import { sendToDataLayer } from '../../../util/index.js'

export const ErrorMessage = ({ postPaymentData: { data, status } }) => {
  const { locale, userLocation } = usePayment()

  useEffect(() => {
    sendToDataLayer(locale, userLocation, 'Donate Failed')
  }, [locale, userLocation])

  const hasErrorMessage = data && data.error
  const shouldShowDonorboxButton = status && status >= 500

  return (
    <>
      <Header viewScreen={'Error screen'}>
        <StartOverButton />
      </Header>
      <Body>
        <div className="ciicc-modal__wrap">
          <h2 className="ciicc-modal__title text -uppercase -bold">
            <FormattedMessage id="checkout.error.title" />
          </h2>
          <p className="ciicc-modal__copy">
            <FormattedMessage id="checkout.error.copy" />
          </p>
          {/*
            This error message will most likely come from the payment endpoint,
            so we probably won't be able to control the language.
          */}
          {hasErrorMessage && (
            <p className="ciicc-modal__copy">
              <FormattedMessage id="checkout.error.message" /> {data.error}
            </p>
          )}
          {shouldShowDonorboxButton && (
            <DonorboxButton className="ciicc-button -blue">
              <FormattedMessage id="checkout.error.button" />
            </DonorboxButton>
          )}
        </div>
      </Body>
    </>
  )
}
