import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CreditCardForm } from '../../payment/credit-card'

export const CardForm = ({
  billingAddress,
  onPaymentError,
  onPaymentSuccess,
  locale,
  userLocation
}) => {
  return (
    <div className="ciicc-modal__wrap">
      <h2 className="ciicc-modal__title text -bold centered-text">
        <FormattedMessage id="checkout.cc.title" />
      </h2>
      <CreditCardForm
        billingAddress={billingAddress}
        onPaymentError={onPaymentError}
        onPaymentSuccess={onPaymentSuccess}
      />
    </div>
  )
}
