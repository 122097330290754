import React from 'react'
import { FormattedMessage } from 'react-intl'

import { usePayment } from '../../../hooks/use-payment'
import back from '../../../assets/images/back-gray.png'

export const StartOverButton = () => {
  const {
    actions: { startOver }
  } = usePayment()
  return (
    <button
      onClick={startOver}
      className="unset-button ciicc-modal__header__text-button"
    >
      <div className="ciicc-icon -back">
      <img src={back} alt="close" />
      </div>

      <span className="span-backbutton">
        <FormattedMessage id="checkout.restart.button" />
      </span>
    </button>
  )
}

export const BackButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="unset-button ciicc-modal__header__text-button"
    >
      <div className="ciicc-icon -back">
      <img src={back} alt="close" />
      </div>
      <span>
        <FormattedMessage id="checkout.back" />
      </span>
    </button>
  )
}
