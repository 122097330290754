import React, { useState, useEffect } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { FormattedMessage, injectIntl } from 'react-intl'

import { LoadingView } from '../../loading-view'
import {
  createPaymentIntent,
  confirmPayment,
  amountToStripe
} from '../../../services/payment'
import { formatCurrency } from '../../../util'
import { usePayment } from '../../../hooks/use-payment'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  classes: {
    base: 'ciicc-input',
    invalid: 'ciicc-labeled-input-invalid'
  }
}

export const CheckoutForm = injectIntl(
  ({ billingAddress, onPaymentError, onPaymentSuccess }) => {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const {
      totalDonationAmount,
      formattedTotalDonationAmount,
      processingFee,
      processingFeeIncluded,
      donationCurrency,
      locale,
      userLocation,
      donorTonnage,
      donationDescription,
      donationCampaign,
      reCampaign,
      actions: { toggleProcessingFeeIncluded }
    } = usePayment()

    const threeDSecureRedirect = `${window.location.origin}/${locale}/3ds-complete`

    const handleDonationOffsetChange = e => {
      toggleProcessingFeeIncluded(e.target.checked)
    }

    // Handle real-time validation errors from the card Element.
    const handleChange = event => {
      if (event.error) {
        setError(event.error.message)
      } else {
        setError(null)
      }
    }

    const handleSubmit = async event => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault()

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        return
      }

      setLoading(true)

      const card = elements.getElement(CardElement)
      const result = await stripe.createToken(card)

      if (result.error) {
        // Inform the user if there was an error.
        setError(result.error.message)
        setLoading(false)
        return
      }

      setError(null)

      const payload = {
        ...billingAddress,
        payment_method: result.token.id,
        donationAmount: amountToStripe(totalDonationAmount, donationCurrency),
        locale,
        donorTonnage,
        donationCurrency,
        donationDescription,
        donationCampaign,
        reCampaign
      }

      const response = await createPaymentIntent(payload)

      setLoading(false)

      if (response.error) {
        onPaymentError(response)
        return
      }

      setLoading(true)
      const confirmParams = {
        client_secret: response.intent.client_secret,
        intent: response.intent.id, // ID of PaymentIntent from Stripe
        redirect_url: threeDSecureRedirect
      }
      const paymentResult = await confirmPayment(confirmParams)
      setLoading(false)
      handlePaymentResult(paymentResult, response.intent.client_secret)
    }

    async function handlePaymentResult(response, intentSecret) {
      if (response.error) {
        onPaymentError(response)
        return
      }

      const intent = response.intent || response.paymentIntent

      if (!intent) {
        onPaymentError({ error: 'Unable to process payment information.' })
      } else if (intent.status === 'requires_action' && intentSecret) {
        // https://stripe.com/docs/payments/3d-secure#web
        // Show the authentication UI in a pop-up modal
        const actionResult = await stripe.confirmCardPayment(intentSecret)
        handlePaymentResult(actionResult)
      } else if (intent.status === 'succeeded') {
        // https://stripe.com/docs/payments/intents#intent-statuses
        onPaymentSuccess()
      } else if (intent.status === 'processing') {
        console.warn('Payment transaction completed with pending status.')
        onPaymentSuccess()
      } else {
        onPaymentError({ errror: 'Payment cancelled.' })
      }
    }

    const cardOptions = {
      ...CARD_OPTIONS,
      hidePostalCode: true,
      value: {
        postalCode: billingAddress.zip
      }
    }

    useEffect(() => {
      toggleProcessingFeeIncluded(true)

      // run on mount: deps should remain `[]`
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <form onSubmit={handleSubmit}>
        <div className="ciicc-modal__checkout-form">
          <div className="ciicc-modal__card-details">
            <label className="screenreader-only">
              <FormattedMessage id="checkout.cc.number" />
            </label>
            <CardElement
              id="card-element"
              onChange={handleChange}
              options={cardOptions}
            />
            <div className="ciicc-form-errors" role="alert">
              {error}
            </div>
          </div>
          <div className="ciicc-modal__text">
            <div className="ciicc-checkbox-row">
              <input
                className="ciicc-checkbox"
                id="offset"
                name="offset"
                type="checkbox"
                onChange={handleDonationOffsetChange}
                checked={processingFeeIncluded}
              />
              <label htmlFor="offset" className="text -light">
                <FormattedMessage
                  id="checkout.cc.processing_offset_optin"
                  values={{
                    value: formatCurrency(
                      processingFee,
                      locale,
                      userLocation,
                      donationCurrency
                    )
                  }}
                />
              </label>
            </div>
          </div>
          <button
            type="submit"
            disabled={!stripe || isLoading}
            className="ciicc-button full-width"
          >
            <FormattedMessage
              id="checkout.cc.donate"
              values={{
                value: formattedTotalDonationAmount
              }}
            />
          </button>
        </div>

        {isLoading && (
          <LoadingView
            wrapperStyle={{ textAlign: 'center' }}
            imageStyle={{ maxWidth: '64px', marginBottom: '-32px' }}
          />
        )}
      </form>
    )
  }
)
