import React from 'react'
import { FormattedMessage } from 'react-intl'
import ResultsComparisonPieChart from './results-comparison-piechart'
import { ResultsComparisonGraphs } from './results-comparison-graphs'

export const ResultsComparison = ({
  carbonImpact,
  airTravel,
  transportation,
  householdAndDiet,
  region,
  type
}) => {
  return (
    <section className="ciicc-section">
      <div className="ciicc-wrap">
        <div className="ciicc-section-heading">
          <h2 className="heading -secondary text -bold-condensed -center">
            <FormattedMessage id="results.comparison.title" />
          </h2>
          <p className="centered-text">
            <FormattedMessage id="results.comparison.subtitle" />
          </p>
        </div>
        <div className="ciicc-results-comparison">
          <ResultsComparisonPieChart
            carbonImpact={carbonImpact}
            airTravel={airTravel}
            transportation={transportation}
            householdAndDiet={householdAndDiet}
          />
          <ResultsComparisonGraphs
            airTravel={airTravel}
            transportation={transportation}
            householdAndDiet={householdAndDiet}
            region={region}
            type={type}
          />
        </div>
      </div>
    </section>
  )
}
