import React from 'react'

/**
 * Custom currency input component.
 */
export const CurrencyInput = ({ value, onChange, onClick, onBlur }) => {
  return (
    <input
      id="unrestricted"
      type="text"
      placeholder="0.00"
      onClick={onClick}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}
