import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formattingValues } from '../util'
import tip1icon from '../assets/images/results-tip-icon1.png'
import tip2icon from '../assets/images/results-tip-icon2.png'
import tip3icon from '../assets/images/results-tip-icon3.png'
import tip4icon from '../assets/images/results-tip-icon4.png'
import tip5icon from '../assets/images/results-tip-icon5.png'
import tip6icon from '../assets/images/results-tip-icon6.png'

export const ResultsTips = () => {
  const generateTips = () => {
    // generate array of tip icons
    const tipIcons = [
      tip1icon,
      tip2icon,
      tip3icon,
      tip4icon,
      tip5icon,
      tip6icon
    ]
  
    return tipIcons.map((icon, index) => (
      <div
        className="ciicc-results-tip"
        key={`results-tip-${index}`}
      >
        <img src={icon} alt="" />
        <h3 className="heading -tertiary text -bold">
          <FormattedMessage
            id={`results.tip${index + 1}.title`}
          />
        </h3>
        <p className="text">
          <FormattedMessage
            id={`results.tip${index + 1}.subtitle`}
            values={formattingValues}
          />
        </p>
        <p className="text -bold">
          <FormattedMessage
            id={`results.tip${index + 1}.offset`}
          />
        </p>
      </div>
    ))
  }

  return (
    <section className="ciicc-section">
      <div className="ciicc-wrap">
        <div className="ciicc-section-heading">
          <h2 className="heading -secondary text -bold-condensed -center">
            <FormattedMessage id="results.tips.title" />
          </h2>
          <p className="centered-text">
            <FormattedMessage id="results.tips.subtitle" />   
          </p>
        </div>
        <div className="ciicc-results-tips layout-grid -wide">
          {generateTips()}
        </div>
      </div>
    </section>
  )
}