import axios from 'axios'

const URL_SHORTENER_ENDPOINT =
  process.env.REACT_APP_URL_SHORTENER_ENDPOINT || ''

const urlShortenerClient = axios.create({
  baseURL: URL_SHORTENER_ENDPOINT
})

export const getShortenedUrl = async path => {
  try {
    const response = await urlShortenerClient.post('', {
      url: path,
      domain: 'car-calc.cc'
    })

    return JSON.parse(response.data.body)
  } catch (error) {
    console.log(error)

    return { url: null }
  }
}
