import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMedia } from '../util'
import { ResultsMoreTabs } from './results-more-tabs'
import { ResultsMoreTabPanels } from './results-more-tabpanels'
import { ResultsMoreAccordion } from './results-more-accordion'

export const ResultsMore = injectIntl(({ intl }) => {
  const mqMatch = useMedia('(min-width: 600px)')

  // number of tabs as defined in constants/strings.js as `results.more.tab${x}`
  const tabsLength = 3
  // generate array of 1 to projectsLength
  const tabKeys = [...Array(tabsLength).keys()].map(x => x + 1)

  const [activeTab, setActiveTab] = useState(mqMatch ? 1 : 0)

  if (mqMatch) {
    return (
      <section className="ciicc-section">
        <div className="ciicc-wrap">
          <div className="ciicc-section-heading">
            <h2 className="heading -secondary text -bold-condensed -center">
              <FormattedMessage id="results.more.title" />
            </h2>
          </div>
          <div className="ciicc-results-tabs">
            <div
              className="ciicc-results-tablist"
              role="tablist"
              aria-label={`${intl.formatMessage({
                id: 'results.more.title'
              })} Menu`}
            >
              <ResultsMoreTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabKeys={tabKeys}
              />
            </div>
          </div>
        </div>
        <div className="ciicc-results-panels ciicc-wrap -xnarrow">
          <ResultsMoreTabPanels activeTab={activeTab} tabKeys={tabKeys} />
        </div>
      </section>
    )
  } else {
    return (
      <section className="ciicc-section">
        <div className="ciicc-wrap">
          <ResultsMoreAccordion
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabKeys={tabKeys}
          />
        </div>
      </section>
    )
  }
})
