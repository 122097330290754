import axios from 'axios'

const LUMINATE_ENDPOINT = process.env.REACT_APP_LUMINATE_ENDPOINT || ''
const luminateClient = axios.create({
  baseURL: LUMINATE_ENDPOINT
})

/**
 * Submits a custom payload to Luminate.
 * @param {Object} data
 * The schema of the payload is:
  ```{
      surveyId -> constant based on Language
      email -> User Input
      emailOptIn -> on;off, based on user input (checkbox)
      firstName -> User input
      lastName -> User input
      shortUrlField -> constant based on Language
      shortUrl -> Generated by shortener-url api
      longUrlField -> constant based on Language
      longUrl -> current page url
      tonnageField -> constant based on Language
      tonnage -> From calculator results
      regionField -> constant based on language
      region -> User input
    }```
 */
export const submitToLuminate = async data => {
  const {
    locale,
    countryCode,
    email,
    firstName,
    lastName,
    tonnage,
    longUrl,
    shortUrl,
    emailOptIn
  } = data

  try {
    const response = await luminateClient.post('', {
      surveyId: LUMINATE_FIELDS.surveyId[locale],
      email,
      emailOptIn: emailOptIn ? 'on' : 'off',
      firstName,
      lastName,
      shortUrlField: LUMINATE_FIELDS.shortUrlField[locale],
      shortUrl,
      longUrlField: LUMINATE_FIELDS.longUrlField[locale],
      longUrl,
      tonnageField: LUMINATE_FIELDS.tonnageField[locale],
      tonnage,
      regionField: LUMINATE_FIELDS.regionField[locale],
      region: countryCode
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

const LUMINATE_FIELDS = {
  surveyId: {
    'en-us': "Ju9GbP3CkkquZwAIX9xoZQ2",
    'es-es': "NVeMWe_3O0GpgnhMEzQksg2",
    'fr-fr': "2u4z99HzbUu9322wpz8INw2",
    'de-de': "zzRpvH7CCEiYB-eVLTJH5w2",
    'ja-jp': "i8g_7zOcakm0PBWkDh7CZw2",
    'ko-kr': "vSr1yElPSESyPIZG5pwBrw2",
    'pt-br': "734QV2PbAUeW9wLYbAWgLQ2",
    'en-gb': "hdwFVk8Z-kuQxrop8ZJO7A2",
    'es-la': "qYuqxP6Q60OpXszAYiAS4Q2"
  },
  regionField: {
    'en-us': '1617_6860_5_9284',
    'es-es': '1617_6880_5_9304',
    'fr-fr': '1617_6903_5_9339',
    'de-de': '1617_6904_5_9344',
    'ja-jp': '1617_6901_5_9329',
    'ko-kr': '1617_6902_5_9334',
    'pt-br': '1617_6900_5_9324',
    'en-gb': '1617_6905_5_9349',
    'es-la': '1617_6920_5_9364'
  },
  shortUrlField: {
    'en-us': '1617_6860_1_9280',
    'es-es': '1617_6880_1_9300',
    'fr-fr': '1617_6903_1_9335',
    'de-de': '1617_6904_1_9340',
    'ja-jp': '1617_6901_1_9325',
    'ko-kr': '1617_6902_1_9330',
    'pt-br': '1617_6900_1_9320',
    'en-gb': '1617_6905_1_9345',
    'es-la': '1617_6920_1_9360'
  },
  longUrlField: {
    'en-us': '1617_6860_4_9283',
    'es-es': '1617_6880_4_9303',
    'fr-fr': '1617_6903_4_9338',
    'de-de': '1617_6904_4_9343',
    'ja-jp': '1617_6901_4_9328',
    'ko-kr': '1617_6902_4_9333',
    'pt-br': '1617_6900_4_9323',
    'en-gb': '1617_6905_4_9348',
    'es-la': '1617_6920_4_9363'
  },
  tonnageField: {
    'en-us': '1617_6860_3_9282',
    'es-es': '1617_6880_3_9302',
    'fr-fr': '1617_6903_3_9337',
    'de-de': '1617_6904_3_9342',
    'ja-jp': '1617_6901_3_9327',
    'ko-kr': '1617_6902_3_9332',
    'pt-br': '1617_6900_3_9322',
    'en-gb': '1617_6905_3_9347',
    'es-la': '1617_6920_3_9362'
  }
}
