import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY
)

export const StripeElements = props => {
  return (
    <Elements stripe={stripePromise} options={props.options}>
      {props.children}
    </Elements>
  )
}
