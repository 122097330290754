/**
 * Calculator Data Table
 * Asia
 */
export const NEW_ZEALAND = {
	continent: 'AS',
	energy: {
	  A: 0.03,
	  B: 0.41,
	  C: 1.17,
	  D: 1.55,
	  E: 1.32
	},
	diet: {
	  A: 3.47,
	  B: 2.67,
	  C: 2.12,
	  D: 1.41,
	  E: 0.78
	},
	transit: {
	  above: 0.000115,
	  below: 0.000101,
	  bus: 0.000069
	},
	transportation: {
	  driving: {
		gas: 0.0023,
		diesel: 0.0027,
		electric: 0.0
	  },
	  air: 0.0001
	},
	goods: 3,
	average: {
	  annualFootprint: {
		individual: 7.2,
		household: 28.78
	  },
	  housingAndDiet: {
		individual: 2.78,
		household: 11.1
	  },
	  groundTransportation: {
		individual: 0.7,
		household: 2.1
	  },
	  airTravel: {
		individual: 0.21,
		household: 0.58
	  }
	}
  }
  