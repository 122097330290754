/**
 * Calculator Data Table
 * Japan
 */
export const JAPAN = {
  continent: 'AS',
  energy: {
    A: 0.01,
    B: 1.18,
    C: 3.32,
    D: 4.41,
    E: 4.06
  },
  diet: {
    A: 2.76,
    B: 2.12,
    C: 1.69,
    D: 1.12,
    E: 0.62
  },
  transit: {
    above: 0.00002,
    below: 0.00002,
    bus: 0.000066
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 4,
  average: {
    annualFootprint: {
      individual: 9.45,
      household: 37.81
    },
    housingAndDiet: {
      individual: 3.53,
      household: 8.12
    },
    groundTransportation: {
      individual: 0.77,
      household: 1.33
    },
    airTravel: {
      individual: 0.19,
      household: 0.34
    }
  }
}
