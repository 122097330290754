import React from 'react'

export const CalculatorContext = React.createContext({
  calculator: null,
  factors: null,
  locale: null,
  userLocation: null
})

export function useCalculator() {
  let context = React.useContext(CalculatorContext)

  if (context === undefined) {
    throw new Error('useCalculator must be used within a CalculatorProvider')
  }

  return context
}
