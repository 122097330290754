import axios from 'axios'
import queryString from 'query-string'

const CONSTITUENT_ENDPOINT =
  process.env.REACT_APP_CONSTITUENT_ENDPOINT_URL || ''

const CONSTITUENT_API_KEY = process.env.REACT_APP_CONSTITUENT_API_KEY || ''

const constituentClient = axios.create({
  baseURL: CONSTITUENT_ENDPOINT
})

function constituentURLBuilder({ userEmail }) {
  const currentDomainType = 'footprint.conservation.org'
  const noWelcome = true


  const url = queryString.stringify({
    method: 'create',
    v: '1.0',
    api_key: CONSTITUENT_API_KEY,
    source: encodeURIComponent(currentDomainType),
    sub_source: document.location.pathname,
    primary_email: userEmail.toLowerCase(),
    no_welcome: noWelcome,
    response_format: 'json',
    'email.accepts_email': true,
    
    email: userEmail.toLowerCase(),
    emailOptIn: true,
    surveyId: 'a-tkDigXJ0OPXIjtjvENRw2'
  })

  return `?${url}`
}

export const submitToConstituent = async data => {
  const paramString = constituentURLBuilder(data)

  try {
    const response = await constituentClient.post(paramString)

    return response
  } catch (error) {
    // Even though 403 is technically an error, the Constituent API
    // returns it if the User already exists, so we need to assume
    // success and return instead of throwing an exception.
    if (error.response.status === 403) {
      return error.response
    } else {
      return handleError(error)
    }
  }
}

function handleError(error) {
  if (error.response) {
    return error.response.data
  }

  throw new Error(error)
}
