import React from 'react'
import PieChart from 'react-minimal-pie-chart'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formattingValues } from '../util'

function ResultsComparisonPieChart({
  intl,
  carbonImpact,
  airTravel,
  transportation,
  householdAndDiet
}) {
  return (
    <figure className="ciicc-results-comparison__piechart">
      <figcaption className="ciicc-results-comparison__piechart__info">
        <p className="text -bold-condensed centered-text">
          <FormattedMessage
            id="results.comparison.results"
            values={{
              ...formattingValues,
              value: carbonImpact
            }}
          />
        </p>
      </figcaption>
      <PieChart
        cx={50}
        cy={50}
        data={[
          {
            color: '#4ca950',
            title: intl.formatMessage({ id: 'results.comparison.household' }),
            value: parseFloat(householdAndDiet)
          },
          {
            color: '#ffc600',
            title: intl.formatMessage({
              id: 'results.comparison.transportation'
            }),
            value: parseFloat(transportation)
          },
          {
            color: '#7ecbef',
            title: intl.formatMessage({ id: 'results.comparison.travel' }),
            value: parseFloat(airTravel)
          }
        ]}
        lengthAngle={360}
        lineWidth={100}
        paddingAngle={0}
        radius={50}
        startAngle={0}
        viewBoxSize={[100, 100]}
      />
    </figure>
  )
}

export default injectIntl(ResultsComparisonPieChart)
