/**
 * Calculator Data Table
 * Portugal
 */
export const PORTUGAL = {
  continent: 'EU',
  energy: {
    A: 0.07,
    B: 0.77,
    C: 2.19,
    D: 2.9,
    E: 2.36
  },
  diet: {
    A: 2.87,
    B: 2.21,
    C: 1.76,
    D: 1.16,
    E: 0.65
  },
  transit: {
    above: 0.000028,
    below: 0.00003,
    bus: 0.00024
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 3,
  average: {
    annualFootprint: {
      individual: 5.31,
      household: 21.24
    },
    housingAndDiet: {
      individual: 2.9,
      household: 7.54
    },
    groundTransportation: {
      individual: 1.33,
      household: 2.58
    },
    airTravel: {
      individual: 0.26,
      household: 0.46
    }
  }
}
