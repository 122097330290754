const DONORBOX_URL_PREFIX =
  'https://donorbox.org/conservation-international-offset-footprint-'

const DEFAULT_DONORBOX_PAGE = 'na-en'

/*
  https://vl.basecamphq.com/projects/14642866-international-carbon-offset-calculator/posts/110296407

  expected shape: {
    locale: {
      countryCode: donorboxPageValue
    }
  }
*/
export const DONORBOX_PAGES = {
  'en-us': {
    NA: 'na-en',
    US: 'us-en',
    CA: 'ca-en',
    AS: 'as-en',
    AF: 'af-en'
  },
  'en-gb': {
    EU: 'eu-en',
    GB: 'gb-en',
    SG: 'si-en',
    AU: 'au-en',
    NZ: 'nz-en'
  },
  'es-la': {
    SA: 'sa-sp',
    MX: 'mx-sp',
    AR: 'ar-sp',
    CO: 'co-sp'
  },
  'es-es': {
    ES: 'sp-sp'
  },
  'fr-fr': {
    FR: 'fr-fr'
  },
  'de-de': {
    DE: 'ge-ge'
  },
  'ja-jp': {
    JP: 'jp-jp'
  },
  'ko-kr': {
    KR: 'sk-ko'
  },
  'pt-br': {
    BR: 'br-po',
    PT: 'po-po'
  }
}

export const getDonorboxUrl = ({ locale, countryCode }, donationAmount) => {
  let page = null
  try {
    page = DONORBOX_PAGES[locale][countryCode]
    if (!page) {
      // the country code wasn't mapped,
      // so fall back to the first value from the list
      const firstCountryCode = Object.keys(DONORBOX_PAGES[locale])[0]
      page = DONORBOX_PAGES[locale][firstCountryCode]
    }
  } catch (e) {
    console.warn('Lookup for donorbox failed', e)
  }

  const donorboxPage = page || DEFAULT_DONORBOX_PAGE
  const amount = donationAmount || ''
  return `${DONORBOX_URL_PREFIX}${donorboxPage}?amount=${amount}`
}
