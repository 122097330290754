import axios from 'axios'

import { reportError } from './error-reporting'
import { REGISTRIES } from '../constants/countries'

const PAYMENT_ENDPOINT = process.env.REACT_APP_PAYMENT_ENDPOINT_URL || ''
if (!PAYMENT_ENDPOINT.length) {
  console.warn('Payment endpoint URL not found. Please see .env.example file.')
}

const paymentClient = axios.create({
  baseURL: PAYMENT_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
})

export async function createPaymentIntent(payload = {}) {
  payload = preparePayment(payload)
  try {
    const response = await paymentClient.post('/intent', payload)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export async function confirmPayment(payload = {}) {
  try {
    const response = await paymentClient.post('/process', payload)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const handleError = error => {
  if (error.response) {
    if (error.response.status >= 500) {
      reportError({
        type: `Payment Endpoint Error`,
        message: error
      })
    }
    return {
      error: true,
      data: error.response.data,
      status: error.response.status
    }
  }
  reportError({
    message: error
  })
  throw new Error(error)
}

function preparePayment(payload) {
  const { donorTonnage, reCampaign, locale, ...attrs } = payload

  const formattedLocale = formatPaymentLocale(locale)

  const result = {
    ...attrs,
    donorLanguage: formattedLocale,
    locale: formattedLocale,
    'RE Campaign': reCampaign
  }

  if (donorTonnage) {
    result['donorTonnage'] = donorTonnage
  }

  return result
}

// https://stripe.com/docs/currencies#zero-decimal
const zeroDecimalCurrencies = ['JPY', 'KRW']

export function amountToStripe(amount, currency) {
  let subUnitAmount = amount
  if (!zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    subUnitAmount = parseFloat(amount) * 100
  }
  return Math.round(subUnitAmount).toString()
}

function formatPaymentLocale(locale) {
  try {
    return REGISTRIES[locale].replace('-', '_')
  } catch (e) {
    console.warn('Error determining payment locale', e)
    return null
  }
}
