//const FUNDRAISEUP_URL_PREFIX = 'https://give.conservation.org/?'
//const FUNDRAISEUP_URL_PREFIX = 'https://conservationintl.donorsupport.co/-/'

const DEFAULT_FUNDRAISEUP_PAGE = '&form=CARCAL-USD-'

/*
  https://vl.basecamphq.com/projects/14642866-international-carbon-offset-calculator/posts/110296407

  expected shape: {
    locale: {
      countryCode: fundraiseupPageValue
    }
  }
*/
export const FUNDRAISEUP_PAGES = {
  "ARS": "CARCAL-ARS-",
  "AUD": "CARCAL-AUD-",
  "BRL": "CARCAL-BRL-",
  "CAD": "CARCAL-CAD-",
  "COP": "CARCAL-COP-",
  "EUR": "CARCAL-EUR-",
  "GBP": "CARCAL-GBP-",
  "JPY": "CARCAL-JPY-",
  "KRW": "CARCAL-KRW-",
  "MXN": "CARCAL-MXN-",
  "SGD": "CARCAL-SGD-",
  "USD": "CARCAL-USD-",
  "NZD": "CARCAL-NZD-"
}

// export const CIUnrestrictedPages = {
//   'de-de': "https://www.conservation.org/de/act/car-cal/schutz-der-natur?",
//   'en-gb': "https://www.conservation.org/act/car-cal/protect-nature?",
//   'en-us': "https://www.conservation.org/act/car-cal/protect-nature?",
//   'es-es': "https://www.conservation.org/es/act/car-cal/proteger-la-naturaleza?",
//   'es-la': "https://www.conservation.org/es/act/car-cal/proteger-la-naturaleza?",
//   'fr-fr': "https://www.conservation.org/fr/act/car-cal/protegez-la-nature?",
//   'ja-jp': "https://www.conservation.org/ja/act/car-cal/shizen-o-hozen-suru?",
//   'ko-kr': "https://www.conservation.org/ko/act/car-cal/jayeon-boho?",
//   'pt-br': "https://www.conservation.org/pt/act/car-cal/proteja-a-natureza?"
// }
// export const CIRestrictedPages = {
//   'de-de': "https://www.conservation.org/de/act/car-cal/gleichen-sie-ihre-bilanz-aus?",
//   'en-gb': "https://www.conservation.org/act/car-cal/offset-your-carbon-footprint?",
//   'en-us': "https://www.conservation.org/act/car-cal/offset-your-carbon-footprint?",
//   'es-es': "https://www.conservation.org/es/act/car-cal/compense-su-huella?",
//   'es-la': "https://www.conservation.org/es/act/car-cal/compense-su-huella?",
//   'fr-fr': "https://www.conservation.org/fr/act/car-cal/compensez-votre-empreinte?",
//   'ja-jp': "https://www.conservation.org/ja/act/car-cal/tanso-haishutsu-ryo-o-sosai-suru?",
//   'ko-kr': "https://www.conservation.org/ko/act/car-cal/tanso-baljagug-eul-sangswaehaseyo?",
//   'pt-br': "https://www.conservation.org/pt/act/car-cal/compense-sua-pegada?"
// }

export const CIUnrestrictedPages = {
  'de-de': "https://give.conservation.org/page/",
  'en-gb': "https://give.conservation.org/page/",
  'en-us': "https://give.conservation.org/page/",
  'es-es': "https://give.conservation.org/page/",
  'es-la': "https://give.conservation.org/page/",
  'fr-fr': "https://give.conservation.org/page/",
  'ja-jp': "https://give.conservation.org/page/",
  'ko-kr': "https://give.conservation.org/page/",
  'pt-br': "https://give.conservation.org/page/"
}
export const CIRestrictedPages = {
  'de-de': "https://give.conservation.org/page/",
  'en-gb': "https://give.conservation.org/page/",
  'en-us': "https://give.conservation.org/page/",
  'es-es': "https://give.conservation.org/page/",
  'es-la': "https://give.conservation.org/page/",
  'fr-fr': "https://give.conservation.org/page/",
  'ja-jp': "https://give.conservation.org/page/",
  'ko-kr': "https://give.conservation.org/page/",
  'pt-br': "https://give.conservation.org/page/"
}


export const getFundraiseupUrl = ({ donationCurrency, locale }, donationAmount) => {
  //console.warn(donationCurrency, locale, donationAmount);

  let CIUPage=CIUnrestrictedPages[locale] || CIUnrestrictedPages["en-us"];
  let CIRPage=CIRestrictedPages[locale] || CIRestrictedPages["en-us"];
  let page = null
  let formType="UR";
  if(donationAmount){formType="R"};
  let alternateCurrency = document.getElementById("currency");
  if(alternateCurrency)
  {
    donationCurrency = alternateCurrency.value;
  }
  try {
    page = FUNDRAISEUP_PAGES[donationCurrency]
    if (!page) {
      console.warn('---Page Failed')
      // the country code wasn't mapped,
      // so fall back to the first value from the list
      const firstCurrency = Object.keys(FUNDRAISEUP_PAGES)[0]
      page = FUNDRAISEUP_PAGES[firstCurrency]
    }
  } catch (e) {
    console.warn('Lookup for fundraiseUp failed', e)
  }

 
  const fundraiseupPage = page || DEFAULT_FUNDRAISEUP_PAGE
  let amount = donationAmount || ''
  
  //return `${FUNDRAISEUP_URL_PREFIX}${donationCurrency}?amount=${amount}&locale=${locale}&locale=${fundraiseupLocale}`
  //return "/pt-br/results?a=0&c=individual&ci=11.44&h=6.44&r=US&t=0&form=CARCAL-USD-UR"
  let currentUrl = document.location.href;
  if(currentUrl.indexOf("form=")>-1)
  {
    return currentUrl
  }
  else
  {
    if(donationAmount)
    {
      return `${CIRPage}${fundraiseupPage}${formType}?amount=${amount.toFixed(2)}&modifyAmount=no`
      //return `${document.location.href}&${fundraiseupPage}${formType}&amount=${amount}&modifyAmount=no`
    }
    else
    {
      return `${CIUPage}${fundraiseupPage}${formType}`
      //return `${document.location.href}?&${fundraiseupPage}${formType}`
    }
  }
}
