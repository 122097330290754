import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formattingValues } from '../util'

export const ResultsMoreAccordion = ({
  activeTab,
  setActiveTab,
  tabKeys
}) => {
  return tabKeys.map(tabCount => (
    <React.Fragment key={`results-accordion-${tabCount}`}>
      <h3 className={`ciicc-results-accordion${activeTab === tabCount ? ' active' : ''}`}>
        <button
          className="unset-button text"
          aria-expanded={activeTab === tabCount ? 'true' : 'false'}
          aria-controls={`section-${tabCount}`}
          id={`accordion-${tabCount}`}
          onClick={() => { activeTab === tabCount ? setActiveTab(0) : setActiveTab(tabCount) }}
        >
          <FormattedMessage
            id={`results.more.tab${tabCount}.title`}
          />
        </button>
      </h3>
      <div
        className="ciicc-results-accordion__section ciicc-rtf text"
        id={`section-${tabCount}`}
        role="region"
        aria-labelledby={`accordion-${tabCount}`}
        key={`results-accordion-section-${tabCount}`}
        hidden={activeTab !== tabCount}
      >
        <FormattedMessage
          id={`results.more.tab${tabCount}.copy`}
          values={formattingValues}
        />
      </div>
    </React.Fragment>
  ))
}