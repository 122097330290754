import React, { useReducer, useEffect } from 'react'

import { getTargetCurrency } from '../../util'

import {
  CONFIG,
  DONATION_DESCRIPTION_UNR,
  DONATION_CAMPAIGN_UNR,
  DONATION_RE_CAMPAIGN_UNR,
  DONATION_DESCRIPTION_R,
  DONATION_CAMPAIGN_R,
  DONATION_RE_CAMPAIGN_R
} from '../../config'

const getInitialState = ({ locale, userLocation }) => {
  return {
    locale,
    userLocation,
    paymentType: null,
    donationAmount: null,
    processingFee: 0,
    processingFeeIncluded: false,
    restricted: false,
    donationCurrency: getTargetCurrency(userLocation),
    postPaymentStep: null,
    postPaymentData: null,
    donorTonnage: null,
    donationDescription: DONATION_DESCRIPTION_UNR,
    donationCampaign: DONATION_CAMPAIGN_UNR,
    reCampaign: DONATION_RE_CAMPAIGN_UNR
  }
}

const donationPaymentReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DONATION_AMOUNT':
      const donationAmount = parseFloat(action.payload)
      let processingFee = state.processingFee
      if (!isNaN(donationAmount)) {
        processingFee = parseFloat(
          (CONFIG.PROCESSING_PERCENTAGE * donationAmount).toFixed(2)
        )
      }

      return {
        ...state,
        donationAmount,
        processingFee
      }

    case 'SET_CURRENCY':
      return {
        ...state,
        donationCurrency: action.payload
      }

    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.payload
      }

    case 'SET_LOCATION':
      return {
        ...state,
        userLocation: action.payload
      }

    case 'SET_PAYMENT_TYPE':
      return { ...state, paymentType: action.payload }

    case 'SET_RESTRICTED':
      return {
        ...state,
        restricted: true,
        donationDescription: DONATION_DESCRIPTION_R,
        donationCampaign: DONATION_CAMPAIGN_R,
        reCampaign: DONATION_RE_CAMPAIGN_R
      }

    case 'SET_TONNAGE':
      return {
        ...state,
        donorTonnage: action.payload
      }

    case 'SET_POST_PAYMENT':
      return {
        ...state,
        postPaymentStep: action.payload.step,
        postPaymentData: action.payload.data
      }

    case 'TOGGLE_PROCESSING_FEE_INCLUDED':
      return {
        ...state,
        processingFeeIncluded: action.payload
      }

    case 'START_OVER':
      return {
        ...state,
        paymentType: null,
        processingFeeIncluded: false,
        postPaymentStep: null,
        postPaymentData: null
      }

    case 'RESET':
      return getInitialState(state)

    default:
      break
  }
  return state
}

export const DonationPaymentContext = React.createContext(null)

export const DonationPaymentProvider = ({ children, locale, userLocation }) => {
  const [state, dispatch] = useReducer(
    donationPaymentReducer,
    { locale, userLocation },
    getInitialState
  )

  // Ensure that a location change triggers currency change!
  useEffect(() => {
    dispatch({ type: 'SET_LOCALE', payload: locale })
    dispatch({
      type: 'SET_CURRENCY',
      payload: getTargetCurrency(userLocation)
    })
    dispatch({ type: 'SET_LOCATION', payload: userLocation })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, JSON.stringify(userLocation)])

  return (
    <DonationPaymentContext.Provider value={[state, dispatch]}>
      {children}
    </DonationPaymentContext.Provider>
  )
}
