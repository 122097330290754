/**
 * Calculator Data Table
 * Colombia
 */
export const COLOMBIA = {
  continent: 'SA',
  energy: {
    A: 0.03,
    B: 0.37,
    C: 1.05,
    D: 1.39,
    E: 0.95
  },
  diet: {
    A: 1.4,
    B: 1.08,
    C: 0.86,
    D: 0.57,
    E: 0.32
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 1,
  average: {
    annualFootprint: {
      individual: 1.66,
      household: 6.62
    },
    housingAndDiet: {
      individual: 1.29,
      household: 4.5
    },
    groundTransportation: {
      individual: 0.5,
      household: 1.31
    },
    airTravel: {
      individual: 0.13,
      household: 0.24
    }
  }
}
