import React from 'react'
import AppendHead from 'react-append-head'

export const Analytics = () => {
  const environment = process.env.NODE_ENV
  const isStaging = environment === 'staging'
  const isProduction = environment === 'production'

  return (
    <React.Fragment>
      {isStaging && (
        <AppendHead>
          <script
            name="onetrust1"
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/bdf320cb-7f39-4c74-a4c7-5f45d3aaf250-test/OtAutoBlock.js"
            data-document-language="true"
            order="0"
          />
          <script
            name="onetrust2"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script="bdf320cb-7f39-4c74-a4c7-5f45d3aaf250-test"
            order="1"
          />
          <script name="onetrust3" type="text/javascript" order="2">
            {` function OptanonWrapper() { } `}
          </script>
          <script name="gtm" order="3" type="text/plain" class="optanon-category-C0002-C0003-C0004-C0005">>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-594HVV');`}
          </script>
        </AppendHead>
      )}

      {isProduction && (
        <AppendHead>
          <script
            name="onetrust1"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script="bdf320cb-7f39-4c74-a4c7-5f45d3aaf250"
            data-document-language="true"
            order="0"
          ></script>
          <script name="onetrust2" type="text/javascript" order="1">
            {` function OptanonWrapper() { } `}
          </script>
          <script name="gtm" order="3" type="text/plain" class="optanon-category-C0002-C0003-C0004-C0005">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-594HVV');`}
          </script>
        </AppendHead>
      )}
    </React.Fragment>
  )
}
