import React from 'react'
import { getExchangeRates } from '../services/currency'

/**
 * Fetches currency conversion quote given a target currency.
 */
export const useCurrencyRate = destination => {
  const [rate, setRate] = React.useState(null)

  React.useEffect(() => {
    if (!destination) {
      return
    }

    const fetchExchangeRates = async () => {
      const { quotes } = await getExchangeRates()

      // @TODO (prayash): Recovery rate if there's a failure here?
      const rate = quotes.find(q => q.quote_currency === destination)

      setRate(parseFloat(rate.average_midpoint))
    }

    fetchExchangeRates()
  }, [destination])

  return [rate]
}
