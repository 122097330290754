import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useModal } from '../hooks/use-modal'
import { usePayment } from '../hooks/use-payment'
import { DonateButton } from './donate-button'

import { sendToDataLayer, formatCurrency } from '../util'

import {
  formattingValues,
  round,
  calcHectares,
  getTargetCurrency
} from '../util'
import { USD_PER_TONNE } from '../calculator/constants'
import { useCurrencyRate } from '../hooks/use-currency-rates'
import { CURRENCY_MAP, BLACKLIST } from '../constants/countries'

export const ResultsHero = ({ carbonImpact, region, locale, userLocation }) => {
  const [targetCurrency, setTargetCurrency] = React.useState(
    getTargetCurrency(userLocation)
  )

  const currencyOptions = generateCurrencyOptions()

  const {
    isOpen,
    actions: { openModal }
  } = useModal()

  const {
    postPaymentStep,
    actions: { setDonationAmount, setRestricted, setCurrency, setTonnage }
  } = usePayment()

  const [rate] = useCurrencyRate(targetCurrency)

  const [postDonation, setPostDonation] = React.useState(false)
  const [isCurrencyChangeable, setCurrencyChangeable] = React.useState(false)

  React.useEffect(() => {
    if (postPaymentStep === 'success') {
      setPostDonation(true)
    }
  }, [postPaymentStep])

  React.useEffect(() => {
    if (rate) {
      setCurrency(targetCurrency)
      setDonationAmount(offsetPriceInTargetCurrency)
      setTonnage(carbonImpact)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate, isOpen])

  if (!rate) {
    return null
  }

  const isBlacklisted = BLACKLIST.has(userLocation.countryCode)
  const hectares = calcHectares(carbonImpact)
  const offsetPriceUsd = carbonImpact * USD_PER_TONNE
  const offsetPriceInTargetCurrency = round(offsetPriceUsd * rate /100) * 100
  const priceDisplay = formatCurrency(
    offsetPriceInTargetCurrency,
    region,
    userLocation,
    targetCurrency
  )

  const updateCurrency = e => {
    setTargetCurrency(e.target.value)
  }

  const handleDonateButtonClick = ({ shouldOpenModal = true } = {}) => {
    sendToDataLayer(locale, userLocation, '5 - Donate', 'Results')
    setRestricted(true)
    shouldOpenModal && openModal()
  }

  const revealCurrenySelector = () => {
    setCurrencyChangeable(true)
  }

  return (
    <section className="ciicc-results-hero ciicc-section">
      <div className="ciicc-results-hero__sections layout-grid -no-padding-sm">
        <div className="ciicc-results-hero__info-section">
          <h2 className="ciicc-results-hero__title heading -secondary text -bold-condensed">
            <FormattedMessage
              id="results.hero.title"
              values={{
                ...formattingValues,
                value: carbonImpact,
                sub: (...chunks) => <sub>{chunks}</sub>
              }}
            />
          </h2>

          <div className="ciicc-results-hero__infographic">
            <p className="ciicc-results-hero__info text -bold">
              <FormattedMessage
                id="results.hero.info"
                values={{
                  value: hectares
                }}
              />
            </p>
          </div>
          <p className="ciicc-results-hero__info-subtitle">
            <FormattedMessage
              id="results.hero.info.subtitle"
              values={{
                sub: (...chunks) => <sub>{chunks}</sub>
              }}
            />
          </p>
        </div>
        <div className="ciicc-results-hero__cta-section">
          <div className="ciicc-results-hero__cta">
            <h3 className="heading -tertiary text -bold">
              <FormattedMessage id="results.hero.cta.title" />
            </h3>
            <div className="ciicc-rtf text">
              <FormattedMessage
                id="results.hero.cta.copy"
                values={{
                  ...formattingValues,
                  value: hectares
                }}
              />
            </div>
            {!isBlacklisted && (
              <>
                {!postDonation ? (
                  <>
                    <p className="centered-text heading -tertiary text -bold">
                      {priceDisplay}
                    </p>
                    <DonateButton
                      id="results.hero.cta.button"
                      onClick={handleDonateButtonClick}
                    />
                    <div className="ciicc-rtf ciicc-results-hero__cta__currency">
                      {!isCurrencyChangeable ? (
                        <button
                          className="unset-button centered-text link text -white"
                          onClick={revealCurrenySelector}
                        >
                          <FormattedMessage id="results.hero.convert_currency" />
                        </button>
                      ) : (
                        <>
                          <label
                            htmlFor="currency"
                            className="screenreader-only"
                          >
                            <FormattedMessage id="results.hero.convert_currency" />
                          </label>
                          <select
                            className="ciicc-select -white"
                            id="currency"
                            value={targetCurrency}
                            onChange={e => updateCurrency(e)}
                          >
                            {currencyOptions}
                          </select>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <p className="ciicc-results-hero__post-donation">
                    <FormattedMessage
                      id="results.hero.cta.confirmation.title"
                      values={{
                        ...formattingValues
                      }}
                    />
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

const generateCurrencyOptions = () => {
  const countries = Object.keys(CURRENCY_MAP)
  return countries.map((currency, index) => (
    <option
      value={CURRENCY_MAP[currency].title}
      key={`currency-option-${index}`}
    >
      {CURRENCY_MAP[currency].symbol} {CURRENCY_MAP[currency].title}
    </option>
  ))
}
