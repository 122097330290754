import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ApplePayButton } from '../../payment/apple-pay'

export const PaymentChoices = ({
  onPaymentError,
  onPaymentSuccess,
  onSubmit,
  supportsApplePay
}) => (
  <div className="ciicc-modal__buttons">
    {supportsApplePay && (
      <ApplePayButton
        onSubmit={onSubmit}
        onPaymentError={onPaymentError}
        onPaymentSuccess={onPaymentSuccess}
      />
    )}
    <button
      className="ciicc-button -red"
      type="button"
      onClick={() => {
        if (typeof onSubmit === 'function') {
          onSubmit('creditCard').catch(function() {
            // do nothing
          })
        }
      }}
    >
      <FormattedMessage id="checkout.donate_cc_button" />
    </button>
  </div>
)
