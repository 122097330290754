/**
 * Calculator Data Table
 * Australia
 */
export const AUSTRALIA = {
  continent: 'OC',
  energy: {
    A: 0.08,
    B: 1.13,
    C: 3.23,
    D: 1.28,
    E: 3.82
  },
  diet: {
    A: 1.54,
    B: 1.18,
    C: 0.94,
    D: 0.62,
    E: 0.35
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0
    },
    air: 0.0001
  },
  goods: 5,
  average: {
    annualFootprint: {
      individual: 12.27,
      household: 49.1
    },
    housingAndDiet: {
      individual: 2.43,
      household: 8.02
    },
    groundTransportation: {
      individual: 1.83,
      household: 4.53
    },
    airTravel: {
      individual: 0.61,
      household: 1.69
    }
  }
}
