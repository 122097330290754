import { useContext } from 'react'

import { DonationPaymentContext } from '../components/donate/payment-container'
import { formatCurrency } from '../util'

export const usePayment = () => {
  const [state, dispatch] = useContext(DonationPaymentContext)
  const setDonationAmount = payload => {
    dispatch({
      type: 'SET_DONATION_AMOUNT',
      payload
    })
  }

  const toggleProcessingFeeIncluded = payload => {
    dispatch({
      type: 'TOGGLE_PROCESSING_FEE_INCLUDED',
      payload
    })
  }

  const setCurrency = payload => {
    dispatch({
      type: 'SET_CURRENCY',
      payload
    })
  }

  const setPaymentType = payload => {
    dispatch({
      type: 'SET_PAYMENT_TYPE',
      payload
    })
  }

  const setPostPayment = payload => {
    dispatch({
      type: 'SET_POST_PAYMENT',
      payload
    })
  }

  const setRestricted = () =>
    dispatch({
      type: 'SET_RESTRICTED'
    })

  const setTonnage = payload =>
    dispatch({
      type: 'SET_TONNAGE',
      payload
    })

  const startOver = () =>
    dispatch({
      type: 'START_OVER'
    })

  const reset = () =>
    dispatch({
      type: 'RESET'
    })

  const {
    donationAmount,
    donationCurrency,
    processingFee,
    processingFeeIncluded,
    locale,
    userLocation
  } = state

  const formattedDonationAmount = formatCurrency(
    donationAmount,
    locale,
    userLocation,
    donationCurrency
  )

  const totalDonationAmount = processingFeeIncluded
    ? donationAmount + processingFee
    : donationAmount

  const formattedTotalDonationAmount = formatCurrency(
    totalDonationAmount,
    locale,
    userLocation,
    donationCurrency
  )

  return {
    ...state,
    formattedDonationAmount,
    formattedTotalDonationAmount,
    totalDonationAmount,
    actions: {
      setDonationAmount,
      toggleProcessingFeeIncluded,
      setPaymentType,
      setRestricted,
      setTonnage,
      setCurrency,
      setPostPayment,
      startOver,
      reset
    }
  }
}
