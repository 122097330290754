import React, { useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { BLACKLIST } from '../constants/countries'
import { Select } from './form/select'
import { DonateButton } from './donate-button'
import { useModal } from '../hooks/use-modal'
import strings from '../constants/strings'
import logo from '../assets/images/logo.png'
import logoMark from '../assets/images/logo-mark.png'
import globe from '../assets/images/globe.png'
import { getNewPathWithLang, removeLangFromPath, useMedia } from '../util'

export const Header = withRouter(
  ({
    isDonateButtonVisible,
    locale,
    countryCode,
    onRegionChange,
    onLangChange,
    ...router
  }) => {
    const isBlacklisted = BLACKLIST.has(countryCode)
    const regionOptions = generateRegionOptions(locale)
    const languageOptions = generateLanguageOptions()

    const {
      actions: { openModal }
    } = useModal()

    const isLanding = removeLangFromPath(router.location.pathname) === ''

    const [menuExpanded, setMenuExpanded] = useState(false)

    const mqMatch = useMedia('(min-width: 600px)')

    return (
      <IntlProvider locale={locale} messages={strings[locale]}>
        <header className="ciicc-header">
          <div className="ciicc-header__logo-group">
            <Link
              to={`/${locale}/`}
              className="ciicc-header__logo"
              tabIndex="0"
            >
              <h1 className="screenreader-only">
                <FormattedMessage id="home.title" />
              </h1>
              <img className="ciicc-header__logo-desktop" src={logo} alt="" />
              <img
                className="ciicc-header__logo-mobile"
                src={logoMark}
                alt=""
              />
            </Link>
            <p className="text -bold">
              <FormattedMessage id="questionnaire.hero.title" />
            </p>
          </div>
          <nav className="ciicc-header__nav">
            <div
              className={`ciicc-header__menu-group${
                menuExpanded ? ' active' : ''
              }`}
            >
              <button
                className={`ciicc-header__menu-close unset-button${
                  menuExpanded ? ' expanded' : ''
                }`}
                onClick={() => {
                  setMenuExpanded(!menuExpanded)
                }}
                aria-haspopup="true"
                aria-expanded={menuExpanded}
                aria-controls="ciicc-header__selects"
                id="ciicc-header__menu"
              >
                <p className="screenreader-only">Toggle Menu</p>
                <span></span>
                <span></span>
              </button>
              <div
                className="ciicc-header__selects"
                id="ciicc-header__selects"
                aria-labelledby="ciicc-header__menu"
              >
                {!isBlacklisted && isLanding ? (
                  <Select
                    wrapperClass="ciicc-header__select"
                    tabIndex={menuExpanded || mqMatch ? 0 : -1}
                    value={countryCode}
                    id="region_selector"
                    localizedLabelText={<FormattedMessage id="header.label" />}
                    isGrouped={true}
                    options={regionOptions}
                    onChange={e => {
                      const { value: countryCode } = e.currentTarget
                      const { continentCode } = strings.countries.find(
                        c => c.code === countryCode
                      )

                      onRegionChange({ countryCode, continentCode })
                    }}
                  />
                ) : (
                  <RegionIndicator
                    countryCode={countryCode}
                    isBlacklisted={isBlacklisted}
                    locale={locale}
                  />
                )}

                <img src={globe} alt="" width="30" />
                <Select
                  wrapperClass="ciicc-header__select"
                  value={locale}
                  tabIndex={menuExpanded || mqMatch ? 0 : -1}
                  id="language_selector"
                  localizedLabelText={<FormattedMessage id="header.label" />}
                  options={languageOptions}
                  onChange={e => {
                    const langCode = e.target.value

                    onLangChange(langCode)

                    const { history, location } = router
                    const currentPath = `${location.pathname}${location.search}`
                    const finalPath = getNewPathWithLang(currentPath, langCode)

                    history.push(finalPath)
                  }}
                />
              </div>
            </div>
            {isDonateButtonVisible && (
              <DonateButton
                id="header.button.text"
                className="-small"
                onClick={openModal}
              />
            )}
          </nav>
          <div className="ciicc-header__menu-open">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </header>
      </IntlProvider>
    )
  }
)

const generateRegionOptions = locale => {
  return strings.countries.map(({ title, code, continent }) => {
    const translatedTitle = title[locale]
    const translatedContinent = continent[locale]

    return {
      title: translatedTitle,
      value: code,
      continent: translatedContinent
    }
  })
}

const generateLanguageOptions = () => {
  return strings.languages.map(({ title, code }) => {
    return { title, value: code }
  })
}

const RegionIndicator = ({ isBlacklisted, countryCode, locale }) => {
  // We can't fetch countryTitle for blacklisted countries
  let countryTitle = null
  if (!isBlacklisted) {
    countryTitle = strings.countries.find(c => c.code === countryCode).title[
      locale
    ]
  }

  return (
    !isBlacklisted && (
      <p className="ciicc-header__region-indicator">
        <b>
          <FormattedMessage id="header.region.label" />:{' '}
        </b>
        {countryTitle}
      </p>
    )
  )
}
