/**
 * Calculator Data Table
 * France
 */
export const FRANCE = {
  continent: 'EU',
  energy: {
    A: 0.09,
    B: 1.06,
    C: 3,
    D: 3.97,
    E: 2.07
  },
  diet: {
    A: 2,
    B: 1.54,
    C: 1.22,
    D: 0.81,
    E: 0.45
  },
  transit: {
    above: 0.000028,
    below: 0.00003,
    bus: 0.00024
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 3,
  average: {
    annualFootprint: {
      individual: 5.48,
      household: 21.93
    },
    housingAndDiet: {
      individual: 2.03,
      household: 4.46
    },
    groundTransportation: {
      individual: 1.7,
      household: 2.81
    },
    airTravel: {
      individual: 0.21,
      household: 0.38
    }
  }
}
