import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NumericInput } from './form/numeric-input'
import { Vehicle } from '../calculator/vehicle'
import { getMeasurementSystem, sendToDataLayer } from '../util'
import add from '../assets/images/add.png'
import remove from '../assets/images/remove.png'

export const TransportationSurvey = ({
  intl,
  factors,
  setFactors,
  calculator,
  locale,
  userLocation,
  onDone,
  userLocation: { countryCode }
}) => {
  useEffect(() => {
    // send user progress to GTM
    sendToDataLayer(locale, userLocation, '3 - Continue to Transportation')
  }, [locale, userLocation])

  const { transport } = factors

  const saveSelection = e => {
    setFactors({
      ...factors,
      transport: {
        ...transport,
        [e.target.id]: parseInt(e.target.value)
      }
    })
  }

  const updateVehicle = (e, index) => {
    let { name, type, checked, value } = e.target
    let key = name.split('-')[0]
    let vehicleType = checked ? Vehicle.type.electric : Vehicle.type.gas
    let updatedVehicles = transport.qVehicles.map((vehicle, i) =>
      index === i
        ? {
            ...vehicle,
            [key]: type === 'text' ? parseInt(value) : vehicleType
          }
        : vehicle
    )

    setFactors({
      ...factors,
      transport: {
        ...transport,
        qVehicles: updatedVehicles
      }
    })
  }

  const initVehicles = e => {
    e.currentTarget.checked ? addVehicle() : removeAllVehicles()
  }

  const addVehicle = () => {
    setFactors({
      ...factors,
      transport: {
        ...transport,
        qVehicles: [...transport.qVehicles, new Vehicle()]
      }
    })

    setVehicleAction('add')
  }

  const removeVehicle = () => {
    setFactors({
      ...factors,
      transport: {
        ...transport,
        qVehicles: transport.qVehicles.slice(0, -1)
      }
    })

    setVehicleAction('remove')
  }

  const inputRefs = []

  const setRef = ref => {
    inputRefs.push(ref)
  }

  const removeAllVehicles = () => {
    setFactors({
      ...factors,
      transport: {
        ...transport,
        qVehicles: []
      }
    })
  }

  const measurementSystem = getMeasurementSystem(countryCode)

  const focusInput = id => inputRefs[id].focus()

  const [vehicleAction, setVehicleAction] = useState('')

  useEffect(() => {
    if (vehicleAction === 'add') {
      focusInput(transport.qVehicles.length - 1)
    }
  }, [vehicleAction])

  return (
    <div className="ciicc-wrap -xxnarrow">
      <div className="ciicc-questionnaire__content">
        <form
          onSubmit={e => {
            e.preventDefault()
            onDone()
          }}
        >
          <fieldset>
            <legend className="ciicc-questionnaire__content__input">
              <FormattedMessage
                id={`questionnaire.transportation.intro.${calculator.type}`}
              />
            </legend>
            <NumericInput
              id="qAboveGround"
              message="questionnaire.q6"
              unit={`questionnaire.${measurementSystem}.distance`}
              value={parseInt(transport.qAboveGround)}
              onChange={saveSelection}
            />

            <NumericInput
              id="qBelowGround"
              message="questionnaire.q7"
              unit={`questionnaire.${measurementSystem}.distance`}
              value={transport.qBelowGround}
              onChange={saveSelection}
            />

            <NumericInput
              id="qBus"
              message="questionnaire.q8"
              unit={`questionnaire.${measurementSystem}.distance`}
              value={transport.qBus}
              onChange={saveSelection}
              min={0}
            />
          </fieldset>

          <fieldset>
            <div className="ciicc-questionnaire__content__input">
              <p>
                <FormattedMessage id="questionnaire.q9.intro" />
              </p>

              <div className="ciicc-checkbox-row">
                <input
                  className="ciicc-checkbox"
                  type="checkbox"
                  name="qVehicle"
                  onChange={e => initVehicles(e)}
                  id="vehicle"
                />
                <label className="ciicc-radio__label" htmlFor="vehicle">
                  <FormattedMessage id="questionnaire.q9.intro.checkbox" />
                </label>
              </div>
            </div>

            {transport.qVehicles.map((v, i) => (
              <React.Fragment key={`vehicle-group-${i}`}>
                <div className="ciicc-questionnaire__content__input">
                  <label>
                    <FormattedMessage
                      id={`questionnaire.q9.${calculator.type}`}
                    />
                  </label>

                  <div className="ciicc-checkbox-row">
                    <input
                      className="ciicc-checkbox"
                      type="checkbox"
                      name={`qVehicleType-${i}`}
                      onChange={e => updateVehicle(e, i)}
                      defaultChecked={v.isElectric}
                      id={`electric-${i}`}
                      ref={setRef}
                    />
                    <label
                      className="ciicc-radio__label"
                      htmlFor={`electric-${i}`}
                    >
                      <FormattedMessage id="questionnaire.q9.intro.checkbox" />
                    </label>
                  </div>
                </div>

                <NumericInput
                  id={`qAnnualMileage-${i}`}
                  message={`questionnaire.q10.${calculator.type}`}
                  unit={`questionnaire.${measurementSystem}.distance`}
                  value={transport.qVehicles[i].qAnnualMileage}
                  onChange={e => updateVehicle(e, i)}
                  required={true}
                />

                <NumericInput
                  id={`qFuelEconomy-${i}`}
                  message={`questionnaire.q11.${calculator.type}`}
                  unit={`questionnaire.${measurementSystem}.volume`}
                  value={transport.qVehicles[i].qFuelEconomy}
                  onChange={e => updateVehicle(e, i)}
                  required={true}
                  pattern="[1-9]\d*"
                />

                {i > 0 && i === transport.qVehicles.length - 1 && (
                  <div>
                    <button
                      className="ciicc-questionnaire__button -remove unset-button text -bold"
                      type="button"
                      onClick={removeVehicle}
                    >
                      <img src={remove} alt="" />
                      <FormattedMessage id="questionnaire.remove_vehicle" />
                    </button>
                  </div>
                )}

                {i === transport.qVehicles.length - 1 && (
                  <div>
                    <button
                      className="ciicc-questionnaire__button -add unset-button text -bold"
                      type="button"
                      onClick={addVehicle}
                    >
                      <img src={add} alt="" />
                      <FormattedMessage id="questionnaire.add_vehicle" />
                    </button>
                  </div>
                )}
              </React.Fragment>
            ))}
          </fieldset>

          <NumericInput
            id="qAirDistance"
            message={`questionnaire.q12.${calculator.type}`}
            unit={`questionnaire.${measurementSystem}.distance`}
            value={transport.qAirDistance}
            onChange={saveSelection}
          />

          <button className="ciicc-button -blue" type="submit">
            <FormattedMessage id="questionnaire.find_impact" />
          </button>
        </form>
      </div>
    </div>
  )
}
