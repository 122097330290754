import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getShortenedUrl } from '../services/url-shortener'
import { CalculatorContext } from '../calculator/context'
import arrowRight from '../assets/images/arrow-right.png'
import { useLuminate } from '../hooks/use-luminate'

import { sendToDataLayer } from '../util'

export const ResultsSignup = ({ tonnage }) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [emailOptIn, setEmailOptIn] = useState(false)

  const handleFirstNameChange = e => setFirstName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handleEmailOptIn = e => setEmailOptIn(!emailOptIn)

  const [luminate, setLuminateData] = useLuminate()
  const showForm = !luminate.response.success

  return (
    <CalculatorContext.Consumer>
      {({ locale, userLocation }) => (
        <div className="ciicc-section ciicc-luminate-survey">
          <div className="ciicc-wrap">
            <h2 className="centered-text text -light">
              <FormattedMessage id="results.signup.title" />
            </h2>
            <form
              method="post"
              onSubmit={async e => {
                e.preventDefault()

                const currentPath = window.location.href
                const { url } = await getShortenedUrl(currentPath)
                const shortUrl = url ?? currentPath

                setLuminateData({
                  locale,
                  emailOptIn,
                  countryCode: userLocation.countryCode,
                  email,
                  firstName,
                  lastName: null,
                  tonnage,
                  longUrl: currentPath,
                  shortUrl
                })
              }}
            >
              <LuminateSuccess
                luminate={luminate}
                locale={locale}
                userLocation={userLocation}
              />

              {showForm && (
                <div className="ciicc-luminate-survey__form">
                  <div className="ciicc-luminate-survey__field">
                    <input
                      className={`ciicc-input text -light${
                        firstName.length > 0 ? ' has-value' : ''
                      }`}
                      id="first-name"
                      name="first-name"
                      required
                      type="text"
                      value={firstName}
                      onChange={handleFirstNameChange}
                      onInvalid={e => e.currentTarget.classList.add('-invalid')}
                    />
                    <label
                      className="ciicc-luminate-survey__label text -light"
                      htmlFor="first-name"
                    >
                      <FormattedMessage id="results.signup.name" />
                    </label>
                  </div>
                  <div className="ciicc-luminate-survey__field">
                    <input
                      className={`ciicc-input text -light${
                        email.length > 0 ? ' has-value' : ''
                      }`}
                      id="email"
                      name="email"
                      required
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      onInvalid={e => e.currentTarget.classList.add('-invalid')}
                    />
                    <label
                      className="ciicc-luminate-survey__label text -light"
                      htmlFor="email"
                    >
                      <FormattedMessage id="results.signup.label" />
                    </label>
                  </div>

                  <LuminateError luminate={luminate} />

                  <label
                    className="ciicc-luminate-survey__checkbox text -light"
                    htmlFor="opt-in"
                  >
                    <input
                      id="opt-in"
                      name="opt-in"
                      type="checkbox"
                      onChange={handleEmailOptIn}
                      checked={emailOptIn}
                    />
                    <FormattedMessage id="results.signup.optin.label" />
                  </label>
                  <button
                    className="ciicc-button -blue-secondary"
                    type="submit"
                  >
                    <img src={arrowRight} alt="" className="submit-button-arrow-left"/>
                    <span className="screenreader-only">
                      <FormattedMessage id="results.signup.submit" />
                    </span>
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </CalculatorContext.Consumer>
  )
}

const LuminateSuccess = ({ luminate, locale, userLocation }) => {
  if (luminate.response && luminate.response.success) {
    sendToDataLayer(
      locale,
      userLocation,
      'Submit Email Address',
      'Send my results'
    )

    return (
      <p className="centered-text">
        <FormattedMessage id="checkout.confirmation.email_optin.confirmation" />
      </p>
    )
  }

  return null
}

const LuminateError = ({ luminate }) =>
  luminate.error && (
    <p
      className="centered-text"
      style={{ order: 1, width: '100%', marginTop: '1em' }}
    >
      <FormattedMessage id="checkout.confirmation.email_optin.error" />
    </p>
  )
