import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Header, Body } from '../shared/layout'
import { usePayment } from '../../../hooks/use-payment'
import { formattingValues, sendToDataLayer } from '../../../util'
import { submitToConstituent } from '../../../services/constituent'

export const SuccessEmailSignup = injectIntl(({ intl }) => {
  const [emailSignUpSuccess, setEmailSignupSuccess] = React.useState(false)
  const [emailSignupLoadiing, setEmailSignupLoading] = React.useState(false)
  const [emailSignupError, setEmailSignupError] = React.useState(null)

  const { formattedTotalDonationAmount, locale, userLocation } = usePayment()

  useEffect(() => {
    sendToDataLayer(locale, userLocation, '6 - Donate Success')
  }, [locale, userLocation])

  const submitEmail = async e => {
    e.preventDefault()
    setEmailSignupLoading(true)

    const formData = new FormData(e.target)
    const userEmail = formData.get('email_optin')

    const luminateResponse = await submitToConstituent({ userEmail })
    const { status } = luminateResponse

    /**
     * From Daniel:
        Luminate Response:
        200 - OK. User added
        403 - OK. User already exists, but we can handle as successfully subscribed
        Anything else - Failed to add the record/communicate to Luminate.
     */
    if (status === 200 || status === 403) {
      setEmailSignupSuccess(true)
    } else {
      setEmailSignupError(status)
    }

    setEmailSignupLoading(false)

    sendToDataLayer(
      locale,
      userLocation,
      'Submit Email Address',
      'Donation Thank you CTA'
    )
  }

  return (
    <>
      <Header viewScreen={'Thank you screen'} />
      <Body>
        <div className="ciicc-modal__wrap">
          <h2 className="ciicc-modal__title text -bold">
            <FormattedMessage id="checkout.confirmation.title" />
          </h2>
          <p>
            <FormattedMessage
              id="checkout.confirmation.copy"
              values={{
                ...formattingValues,
                value: formattedTotalDonationAmount
              }}
            />
          </p>
        </div>
        
        <div className="ciicc-modal__accent-section">
          <div className="ciicc-modal__wrap">
            {emailSignUpSuccess ? (
              <p>
                <FormattedMessage id="checkout.confirmation.email_optin.confirmation" />
              </p>
            ) : (
              <>
                <h2 className="ciicc-modal__title text -bold">
                  <FormattedMessage id="checkout.confirmation.email_optin.title" />
                </h2>

                {/* <form
                  className="ciicc-modal__accent-section__form"
                  onSubmit={e => submitEmail(e)}
                >
                  <label htmlFor="email_optin" className="screenreader-only">
                    <FormattedMessage id="checkout.confirmation.email_optin.input.label" />
                  </label>
                  <input
                    className="ciicc-input"
                    id="email_optin"
                    name="email_optin"
                    type="email"
                    autoComplete="on"
                    required
                  />
                  {emailSignupError ? (
                    <p className="text -bold ciicc-emailoptin-error">
                      <FormattedMessage id="checkout.error.message" /> Luminate
                      error {emailSignupError}
                    </p>
                  ) : (
                    <button
                      className="ciicc-button -white-secondary text -blue-boy"
                      disabled={emailSignupLoadiing}
                    >
                      <FormattedMessage id="checkout.confirmation.email_optin.button" />
                    </button>
                  )}
                </form> */}
                    <a
                      href="https://www.conservation.org/subscribe"
                      className="ciicc-button -white-secondary text -blue-boy"
                      target="_blank"
                      disabled={emailSignupLoadiing}
                    >
                      <FormattedMessage id="checkout.confirmation.email_optin.button" />
                    </a>
              </>
            )}
          </div>
        </div>

        
      </Body>
    </>
  )
})
