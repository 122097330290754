import React from 'react'

import { useModal } from '../../../hooks/use-modal'
import { usePayment } from '../../../hooks/use-payment'
import { sendToDataLayer } from '../../../util/index.js'

import close from '../../../assets/images/close-gray.png'


export const Header = ({ children, showDonation = false, viewScreen }) => {
  const {
    actions: { closeModal }
  } = useModal()

  const { formattedTotalDonationAmount, locale, userLocation } = usePayment()

  const handleModalClose = () => {
    sendToDataLayer(locale, userLocation, 'Donate Closed', viewScreen)
    closeModal()
  }

  return (
    <header className="ciicc-modal__header">
      <div>{children}</div>
      <div className="ciicc-modal__header__text-button">
        {showDonation && formattedTotalDonationAmount && (
          <h2 className="text -blue-boy -bold">
            {formattedTotalDonationAmount}
          </h2>
        )}
        <span>
          <button onClick={handleModalClose} className="unset-button">
            <img src={close} alt="close" />
          </button>
        </span>
      </div>
    </header>
  )
}

export const Body = ({ children }) => <div>{children}</div>
