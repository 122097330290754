import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CalculatorContext } from '../calculator/context'
import { PageContainer } from '../components/page-container'
import { HouseholdSurvey } from '../components/household-survey'
import { TransportationSurvey } from '../components/transportation-survey'

const SURVEY_TYPE = {
  household: 'household',
  transportation: 'transportation'
}

function Questionnaire({ intl, location, history, setFactors }) {
  const [activeSection, setActiveSection] = React.useState(
    SURVEY_TYPE.household
  )
  const isHouseholdActive = activeSection === SURVEY_TYPE.household
  const isTransportationActive = activeSection === SURVEY_TYPE.transportation

  return (
    <CalculatorContext.Consumer>
      {({ calculator, factors, locale, userLocation }) => (
        <PageContainer>
          <div className="ciicc-questionnaire__hero">
            <h2>
              <FormattedMessage id="questionnaire.hero.title" />
            </h2>
            <p className="heading -primary text -condensed -center">
              <FormattedMessage id={`questionnaire.hero.${calculator.type}`} />
            </p>
          </div>
          <div className="ciicc-wrap -xxnarrow">
            <div className="ciicc-questionnaire__wayfinding" role="tablist">
              <button
                className="ciicc-questionnaire__wayfinding__button"
                role="tab"
                aria-selected={isHouseholdActive ? true : false}
                id="householdTab"
                aria-controls="householdPanel"
                onClick={() => {
                  setActiveSection(SURVEY_TYPE.household)
                }}
              >
                <div className="ciicc-questionnaire__wayfinding__button__container">
                  <FormattedMessage id="questionnaire.household" />
                </div>
              </button>
              <button
                className="ciicc-questionnaire__wayfinding__button"
                role="tab"
                aria-selected={isTransportationActive ? true : false}
                id="transportationTab"
                aria-controls="transportationPanel"
                {...(isHouseholdActive && { disabled: true })}
                onClick={() => {
                  setActiveSection(SURVEY_TYPE.transportation)
                }}
              >
                <div className="ciicc-questionnaire__wayfinding__button__container">
                  <FormattedMessage id="questionnaire.transportation" />
                </div>
              </button>
            </div>
          </div>

          <div>
            <div id="householdPanel" aria-labelledby="householdTab">
              {isHouseholdActive && (
                <HouseholdSurvey
                  intl={intl}
                  factors={factors}
                  setFactors={setFactors}
                  calculator={calculator}
                  userLocation={userLocation}
                  locale={locale}
                  onContinue={() => {
                    setActiveSection(SURVEY_TYPE.transportation)
                  }}
                />
              )}
            </div>
            <div id="transportationPanel" aria-labelledby="transportationTab">
              {isTransportationActive && (
                <TransportationSurvey
                  intl={intl}
                  factors={factors}
                  setFactors={setFactors}
                  calculator={calculator}
                  userLocation={userLocation}
                  locale={locale}
                  onDone={() => {
                    history.push({
                      pathname: `/${intl.locale}/results`,
                      search: calculator.serialize(factors)
                    })
                  }}
                />
              )}
            </div>
          </div>
        </PageContainer>
      )}
    </CalculatorContext.Consumer>
  )
}

export default injectIntl(Questionnaire)
