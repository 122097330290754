/**
 * A struct for representing a Vehicle in the transportation survey.
 */
export class Vehicle {
  static type = {
    gas: 'gas',
    electric: 'electric'
  }

  constructor(
    type = Vehicle.type.gas,
    mileage = undefined,
    fuelEconomy = undefined
  ) {
    this.qVehicleType = type
    this.qAnnualMileage = mileage
    this.qFuelEconomy = fuelEconomy
  }

  get isGas() {
    return this.qVehicleType === Vehicle.type.gas
  }

  get isElectric() {
    return this.qVehicleType === Vehicle.type.electric
  }
}
