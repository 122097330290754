import React, { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'

import {
  createPaymentIntent,
  confirmPayment,
  amountToStripe
} from '../../../services/payment'
import { usePayment } from '../../../hooks/use-payment'

import ApplePayLogo from '../../../assets/images/apple-pay.png'

export const CheckoutButton = ({
  onSubmit,
  onPaymentError,
  onPaymentSuccess
}) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  const {
    donationAmount,
    locale,
    donationCurrency,
    donorTonnage,
    donationDescription,
    donationCampaign,
    reCampaign,
    userLocation: { countryCode },
    actions: { setPaymentType }
  } = usePayment()

  function makePaymentRequest() {
    const stripeAmount = amountToStripe(donationAmount, donationCurrency)

    const prConfig = {
      country: countryCode,
      currency: donationCurrency.toLowerCase(),
      total: {
        label: 'Donation',
        amount: parseInt(stripeAmount)
      },
      requestPayerEmail: true
    }

    const pr = stripe.paymentRequest(prConfig)

    setPaymentRequest(pr)

    pr.on('cancel', () => {
      setPaymentRequest(null)
      setPaymentType(null)
    })

    pr.on('token', async event => {
      const payload = {
        donationAmount: stripeAmount,
        donorEmail: event.payerEmail,
        payment_method: event.token.id, // tok_{id}
        locale,
        donorTonnage,
        donationCurrency,
        donationDescription,
        donationCampaign,
        reCampaign
      }

      const response = await createPaymentIntent(payload)

      if (response.error) {
        onPaymentError(response)
      }

      const confirmParams = {
        client_secret: response.intent.client_secret,
        intent: response.intent.id // ID of PaymentIntent from Stripe
      }

      const paymentResult = await confirmPayment(confirmParams)

      if (paymentResult.error) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        event.complete('fail')
        console.log('Payment failed.')
        onPaymentError(paymentResult)
      } else if (paymentResult.intent.status === 'succeeded') {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete('success')
        console.log('Payment succeeded.')
        onPaymentSuccess()
      } else {
        console.warn(
          'Payment transaction completed but intent status was not successful.'
        )
      }
    })

    // we pre-check apple pay support, but Stripe requires check before show()
    pr.canMakePayment().then(() => {
      try {
        pr.show()
      } catch (e) {
        // Payment Request is not available in this browser
        console.warn(e)
      }
    })
  }

  return (
    <button
      className="ciicc-button -black -minimal-padding"
      type="button"
      onClick={event => {
        if (paymentRequest) {
          return
        }

        // optionally disable unless onSubmit resolves.
        // currently used to validate unrestricted form amount
        if (typeof onSubmit === 'function') {
          onSubmit('applePay')
            .then(makePaymentRequest)
            .catch(error => {
              // do nothing
              console.error(error)
            })
        }
      }}
    >
      {/* TODO: replace with "Donate with Pay" graphic */}
      <img src={ApplePayLogo} alt="" height="20" />
      <span className="screenreader-only">Donate with Apple Pay</span>
    </button>
  )
}
