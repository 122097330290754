import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formattingValues } from '../util'

export const ResultsMoreTabPanels = ({ activeTab, tabKeys }) => {
  return tabKeys.map(tabCount => (
    <div
      className="ciicc-results-tab__panel ciicc-rtf text"
      id={`panel-${tabCount}`}
      role="tabpanel"
      tabIndex={activeTab === tabCount ? '0' : '-1'}
      aria-labelledby={`tab-${tabCount}`}
      key={`results-tab-panel-${tabCount}`}
      hidden={activeTab !== tabCount}
    >
      <FormattedMessage
        id={`results.more.tab${tabCount}.copy`}
        values={formattingValues}
      />
    </div>
  ))
}
