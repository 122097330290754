// src/server.js
import { Server, Response } from 'miragejs'

import { ERROR_ENDPOINT } from '../services/error-reporting'

const MirageResponse = Response

const PAYMENT_ENDPOINT = process.env.REACT_APP_PAYMENT_ENDPOINT_URL || ''

const CONSTITUENT_ENDPOINT =
  process.env.REACT_APP_CONSTITUENT_ENDPOINT_URL || ''

const URL_SHORTENER_ENDPOINT =
  process.env.REACT_APP_URL_SHORTENER_ENDPOINT || ''

const LUMINATE_ENDPOINT = process.env.REACT_APP_LUMINATE_ENDPOINT || ''

export function makeServer({ environment = 'test' }) {
  return new Server({
    environment,

    routes() {
      this.post(
        `${PAYMENT_ENDPOINT}/intent`,
        (schema, request) => {
          return {
            intent: {
              id: 'pi_id',
              client_secret: 'pi_client_secret'
            }
          }

          // use this for errors!
          // return new MirageResponse(500, {}, { error: 'Unknown error' })
        },
        {
          timing: 2000
        }
      )

      this.post(
        `${PAYMENT_ENDPOINT}/process`,
        (schema, request) => {
          // use this for success!
          return { intent: { status: 'succeeded' } }

          // use this for errors!
          // return new MirageResponse(
          //   403,
          //   {},
          //   { error: 'Your card was declined' }
          // )
        },
        {
          timing: 2000
        }
      )

      this.post(
        CONSTITUENT_ENDPOINT,
        (schema, request) => {
          // Contrary to popular belief, 403 is a success for this endpoint.
          return new MirageResponse(
            403,
            {},
            { error: 'Username already exists.' }
          )
        },
        { timing: 1000 }
      )

      this.post(
        URL_SHORTENER_ENDPOINT,
        (schema, request) => {
          return new MirageResponse(
            200,
            {},
            { body: JSON.stringify({ url: 'http://short-url.com' }) }
          )
        },
        { timing: 1000 }
      )

      this.post(
        LUMINATE_ENDPOINT,
        (schema, request) => {
          return new MirageResponse(200, {}, { success: 'Username added.' })
        },
        { timing: 1000 }
      )

      this.post(
        ERROR_ENDPOINT,
        (schema, request) => {
          return new MirageResponse(200, {}, {})
        },
        { timing: 1000 }
      )
      
      this.post(
        "https://api.fundraiseup.com/*",
        (schema, request) => {
          return new MirageResponse(200, {}, {})
        },
        { timing: 1000 }
      )

      // Allow unhandled requests on the current domain to pass through
      this.passthrough()
    }
  })
}
