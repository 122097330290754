import { USA } from './usa'
import { CANADA } from './canada'
import { MEXICO } from './mexico'

/**
 * Energy emissions and diet factors for North & Central America + The Caribbean.
 */
export const NORTH_CENTRAL_AMERICA = {
  name: 'NA',
  energy: {
    A: 0.11,
    B: 1.51,
    C: 4.32,
    D: 5.73,
    E: 3.82
  },
  diet: {
    A: 2.8,
    B: 2.15,
    C: 1.71,
    D: 1.13,
    E: 0.63
  },
  transit: {
    above: 0.000087,
    below: 0.000075,
    bus: 0.000035
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 5,
  average: {
    annualFootprint: {
      individual: 11.36,
      household: 45.44
    },
    housingAndDiet: {
      individual: 3.15,
      household: 11.66
    },
    groundTransportation: {
      individual: 2.9,
      household: 8.06
    },
    airTravel: {
      individual: 0.15,
      household: 0.41
    }
  },
  countries: {
    CANADA,
    USA,
    MEXICO
  }
}
