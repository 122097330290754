import { BaseCalculator } from './base'
import { CALCULATOR_TYPE, UTILITY_FACTORS, REGIONAL_FACTORS } from './constants'
import { round } from '../util'

/**
 * This Calculator object facilitates all carbon calculates for a given household.
 */
export class HouseholdCalculator extends BaseCalculator {
  constructor(userLocation) {
    super(userLocation)

    this.type = CALCULATOR_TYPE.household
  }

  /**
   * (Q1 TYPE FACTOR * Q2 SIZE FACTOR * Q3 ENERGY EMISSIONS) + (Q4 # OF RESIDENTS * Q5 DIET)
   * * @param {Object} homeFactors (see `DEFAULT_HOUSEHOLD_FACTORS`)
   */
  homeFootprint({ qType, qSize, qEmissions, qResidents, qDiet }) {
    const { region } = this

    const typeFactor = UTILITY_FACTORS.type[qType]
    const sizeFactor = UTILITY_FACTORS.size[qSize]
    const energyFactor = REGIONAL_FACTORS[region].energy[qEmissions]
    const diet = REGIONAL_FACTORS[region].diet[qDiet]

    return round(typeFactor * sizeFactor * energyFactor + qResidents * diet)
  }
}
