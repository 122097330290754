/**
 * Calculator Data Table
 * International
 */
export const INTERNATIONAL = {
  continent: 'INTL',
  energy: {
    A: 0.06,
    B: 0.71,
    C: 2.01,
    D: 2.66,
    E: 1.68
  },
  diet: {
    A: 1.43,
    B: 1.1,
    C: 0.87,
    D: 0.58,
    E: 0.32
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0
    },
    air: 0.0001
  },
  goods: 2,
  average: {
    annualFootprint: {
      individual: 4.8,
      household: 19.2
    },
    housingAndDiet: {
      individual: 1.43,
      household: 5.85
    },
    groundTransportation: {
      individual: 1.23,
      household: 3.78
    },
    airTravel: {
      individual: 0.12,
      household: 0.36
    }
  }
}
