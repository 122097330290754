import React from 'react'
import loadingGif from '../assets/images/loading.gif'

export const LoadingView = ({ wrapperStyle = {textAlign: 'center',width: '100%'}, imageStyle = {} }) => (
  <div style={wrapperStyle}>
    <img
      src={loadingGif}
      alt="Loading"
      style={{ width: '30%', ...imageStyle }}
      data-testid="loading-gif"
    />
  </div>
)
