/**
 * Calculator Data Table
 * USA
 */
export const USA = {
  continent: 'NA',
  energy: {
    A: 0.13,
    B: 2.09,
    C: 6.02,
    D: 7.98,
    E: 5.08
  },
  diet: {
    A: 2.96,
    B: 2.28,
    C: 1.81,
    D: 1.2,
    E: 0.67
  },
  transit: {
    above: 0.0002,
    below: 0.0001,
    bus: 0.00006
  },
  transportation: {
    driving: {
      gas: 0.0089,
      diesel: 0.01018,
      electric: 0.0
    },
    air: 0.0002
  },
  goods: 5,
  average: {
    annualFootprint: {
      individual: 16.24,
      household: 64.96
    },
    housingAndDiet: {
      individual: 4.47,
      household: 11.19
    },
    groundTransportation: {
      individual: 4.82,
      household: 9.04
    },
    airTravel: {
      individual: 0.52,
      household: 0.97
    }
  }
}
