/**
 * Calculator Data Table
 * Argentina
 */
export const ARGENTINA = {
  continent: 'SA',
  energy: {
    A: 0.07,
    B: 0.66,
    C: 1.83,
    D: 2.42,
    E: 2.13
  },
  diet: {
    A: 3.25,
    B: 2.5,
    C: 1.99,
    D: 1.31,
    E: 0.73
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      energy: 0.0
    },
    air: 0.0001
  },
  goods: 1,
  average: {
    annualFootprint: {
      individual: 4.62,
      household: 18.46
    },
    housingAndDiet: {
      individual: 3.02,
      household: 9.95
    },
    groundTransportation: {
      individual: 0.5,
      household: 1.24
    },
    airTravel: {
      individual: 0.07,
      household: 0.12
    }
  }
}
