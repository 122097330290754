import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Select } from '../components/form/select'
import {
  generateSelectOptions,
  getMeasurementSystem,
  sendToDataLayer
} from '../util'
import { NumericInput } from './form/numeric-input'

export const HouseholdSurvey = ({
  intl,
  factors,
  setFactors,
  calculator,
  userLocation,
  locale,
  onContinue,
  userLocation: { countryCode }
}) => {
  useEffect(() => {
    // send user progress to GTM
    sendToDataLayer(locale, userLocation, '2 - Calculator Started')
  }, [locale, userLocation])

  const { household } = factors
  const saveSelection = e => {
    let isNumber = e.target.type === 'number'
    let value = isNumber ? e.target.valueAsNumber : e.target.value

    setFactors({
      ...factors,
      household: {
        ...household,
        [e.target.id]: value
      }
    })
  }

  const measurementSystem = getMeasurementSystem(countryCode)

  return (
    <div className="ciicc-wrap -xxnarrow">
      <div className="ciicc-questionnaire__content">
        <form
          onSubmit={e => {
            e.preventDefault()

            onContinue()
          }}
        >
          <Select
            id="qType"
            value={household.qType}
            onChange={saveSelection}
            localizedLabelText={
              <FormattedMessage id={`questionnaire.q1.${calculator.type}`} />
            }
            options={generateSelectOptions([
              intl.formatMessage({
                id: 'questionnaire.q1.option.a'
              }),
              intl.formatMessage({
                id: 'questionnaire.q1.option.b'
              }),
              intl.formatMessage({
                id: 'questionnaire.q1.option.c'
              }),
              intl.formatMessage({
                id: 'questionnaire.q1.option.d'
              })
            ])}
            isLabelHidden={false}
            wrapperClass="ciicc-questionnaire__content__input"
          />

          <Select
            id="qSize"
            value={household.qSize}
            onChange={saveSelection}
            localizedLabelText={
              <FormattedMessage id={`questionnaire.q2.${calculator.type}`} />
            }
            options={generateSelectOptions([
              intl.formatMessage({
                id: `questionnaire.q2.option.a.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.b.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.c.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.d.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.e.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.f.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.g.${measurementSystem}`
              }),
              intl.formatMessage({
                id: `questionnaire.q2.option.h.${measurementSystem}`
              })
            ])}
            isLabelHidden={false}
            wrapperClass="ciicc-questionnaire__content__input"
          />

          <Select
            id="qEmissions"
            value={household.qEmissions}
            onChange={saveSelection}
            localizedLabelText={
              <FormattedMessage id={`questionnaire.q3.${calculator.type}`} />
            }
            options={generateSelectOptions([
              intl.formatMessage({
                id: 'questionnaire.q3.option.a'
              }),
              intl.formatMessage({
                id: 'questionnaire.q3.option.b'
              }),
              intl.formatMessage({
                id: 'questionnaire.q3.option.c'
              }),
              intl.formatMessage({
                id: 'questionnaire.q3.option.d'
              }),
              intl.formatMessage({
                id: 'questionnaire.q3.option.e'
              })
            ])}
            isLabelHidden={false}
            wrapperClass="ciicc-questionnaire__content__input"
          />

          <NumericInput
            id="qResidents"
            message={`questionnaire.q4.${calculator.type}`}
            value={household.qResidents || ''}
            onChange={saveSelection}
            pattern="[1-9]\d*"
            required
          />

          <Select
            id="qDiet"
            value={household.qDiet}
            onChange={saveSelection}
            localizedLabelText={
              <FormattedMessage id={`questionnaire.q5.${calculator.type}`} />
            }
            options={generateSelectOptions([
              intl.formatMessage({
                id: 'questionnaire.q5.option.a'
              }),
              intl.formatMessage({
                id: 'questionnaire.q5.option.b'
              }),
              intl.formatMessage({
                id: 'questionnaire.q5.option.c'
              }),
              intl.formatMessage({
                id: 'questionnaire.q5.option.d'
              }),
              intl.formatMessage({
                id: 'questionnaire.q5.option.e'
              })
            ])}
            isLabelHidden={false}
            wrapperClass="ciicc-questionnaire__content__input"
          />
          <button className="ciicc-button -blue" type="submit">
            <FormattedMessage id="checkout.billing.continue" />
          </button>
        </form>
      </div>
    </div>
  )
}
