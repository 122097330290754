import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

export const ResultsMoreTabs = ({
  activeTab,
  setActiveTab,
  tabKeys
}) => {
  // Never hide all tab content on desktop by overriding hidden state from closed accordions on mobile
  useEffect(
    () => {
      if (activeTab === 0) {
        setActiveTab(1)
      }
    },
    [activeTab, setActiveTab]
  )

  return tabKeys.map(tabCount => (
    <button
      className={`ciicc-results-tab unset-button text -bold${activeTab === tabCount ? ' active' : ''}`}
      role="tab"
      aria-selected={activeTab === tabCount ? 'true' : 'false'}
      aria-controls={`panel-${tabCount}`}
      id={`tab-${tabCount}`}
      onClick={() => { setActiveTab(tabCount) }}
      key={`results-tab-${tabCount}`}
    >
      <FormattedMessage
        id={`results.more.tab${tabCount}.title`}
      />
    </button>
  ))
}