import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Header, Body } from './shared/layout'
import { reportError } from '../../services/error-reporting'

export class ModalErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const { locale, userLocation } = this.props
    reportError({
      message: error.message,
      language: locale || '',
      region: userLocation.countryCode || ''
    })
  }

  render() {
    if (this.state.hasError) {
      return <RuntimeErrorDisplay />
    }
    return this.props.children
  }
}

export const RuntimeErrorDisplay = () => (
  <>
    <Header />
    <Body>
      <div className="ciicc-modal__wrap">
        <h2 className="ciicc-modal__title text -uppercase -bold">
          <FormattedMessage id="checkout.error.title" />
        </h2>
        {/* TODO? we do not have an appropriate set of translated error messages for a runtime error
          <p className="ciicc-modal__copy">
            <FormattedMessage id="checkout.error.copy" />
          </p> */}
        {
          /* TODO: wire up this 'Try again' action to donorbox */
          <button className="ciicc-button -blue">
            <FormattedMessage id="checkout.error.button" />
          </button>
        }
      </div>
    </Body>
  </>
)
