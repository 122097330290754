import { useState, useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'

const paymentRequestParams = {
  country: 'US',
  currency: 'usd',
  total: {
    label: 'Test',
    amount: 100 // 1 dollar
  }
}

export const usePaymentRequest = () => {
  const stripe = useStripe() // must be a child of <StripeElements>
  const [supportsApplePay, setSupportsApplePay] = useState(false)

  useEffect(() => {
    if (!stripe) {
      // We can't create a PaymentRequest until Stripe.js loads
      return
    }

    const pr = stripe.paymentRequest(paymentRequestParams)

    pr.canMakePayment().then(canMakePaymentRes => {
      setSupportsApplePay(
        canMakePaymentRes && canMakePaymentRes.applePay === true
      )
    })
  }, [stripe])

  return {
    supportsApplePay
  }
}
