import React from 'react'
import { PageContainer } from '../components/page-container'
import { LandingProjects } from '../components/landing-projects'
import { LandingHero } from '../components/landing-hero'
import { LandingCta } from '../components/landing-cta'
import { LandingHow } from '../components/landing-how'
import { ResultsMore } from '../components/results-more'

function Landing({ setCalcType }) {
  return (
    <PageContainer>
      <LandingCta setCalcType={setCalcType} />
      <LandingHow />
      <LandingProjects setCalcType={setCalcType} />
      <ResultsMore />
      <LandingHero title={'home.intro.heading'} copy={'home.intro.copy'} />
    </PageContainer>
  )
}

export default Landing
