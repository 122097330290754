/**
 * Calculator Data Table
 * Canada
 */
export const CANADA = {
  continent: 'NA',
  energy: {
    A: 0.16,
    B: 2.12,
    C: 6.04,
    D: 8.01,
    E: 5.26
  },
  diet: {
    A: 2.8,
    B: 2.16,
    C: 1.71,
    D: 1.13,
    E: 0.63
  },
  transit: {
    above: 0.000087,
    below: 0.00011,
    bus: 0.000035
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 5,
  average: {
    annualFootprint: {
      individual: 15.64,
      household: 62.56
    },
    housingAndDiet: {
      individual: 4.29,
      household: 10.29
    },
    groundTransportation: {
      individual: 3.09,
      household: 5.56
    },
    airTravel: {
      individual: 0.47,
      household: 0.84
    }
  }
}
