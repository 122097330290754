import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Header, Body } from './shared/layout'
import { PaymentChoices } from './shared/payment-choices'

import { formattingValues, sendToDataLayer } from '../../util/index.js'
import { usePayment } from '../../hooks/use-payment'

export const RestrictedForm = ({
  onPaymentError,
  onPaymentSuccess,
  supportsApplePay
}) => {
  const {
    formattedDonationAmount,
    locale,
    userLocation,
    actions: { setPaymentType }
  } = usePayment()

  const handlePaymentSubmit = paymentType => {
    if (paymentType) {
      setPaymentType(paymentType)
      sendToDataLayer(
        locale,
        userLocation,
        'Payment Method Selected',
        paymentType
      )
    }

    return Promise.resolve()
  }

  return (
    <>
      <Header viewScreen={'Payment Method'} />
      <Body>
        <div className="ciicc-modal__wrap">
          <h2 className="ciicc-modal__title text -uppercase -bold">
            <FormattedMessage id="checkout.restricted.title" />
          </h2>
          <p className="ciicc-modal__copy">
            <FormattedMessage
              id="checkout.restricted.copy"
              values={formattingValues}
            />
          </p>
          <p className="ciicc-modal__donation text -bold centered-text">
            {formattedDonationAmount}
          </p>
          <PaymentChoices
            onPaymentError={onPaymentError}
            onPaymentSuccess={onPaymentSuccess}
            onSubmit={handlePaymentSubmit}
            supportsApplePay={supportsApplePay}
          />
        </div>
      </Body>
    </>
  )
}
