import axios from 'axios'

export const ERROR_ENDPOINT = process.env.REACT_APP_ERROR_ENDPOINT_URL || ''
if (!ERROR_ENDPOINT.length) {
  console.warn('Error reporting endpoint URL not found. See .env.example file.')
}

const errorReportingClient = axios.create({
  baseURL: ERROR_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
})

export function reportError({
  message,
  language = '',
  region = '',
  type = 'Unknown'
}) {
  try {
    errorReportingClient.post('', {
      message: `Type: ${type}. ${message}.`,
      language,
      region
    })
  } catch (error) {
    console.error(error)
  }
}
