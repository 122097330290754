import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { Header, Body } from './shared/layout'
import { PaymentChoices } from './shared/payment-choices'
import { CurrencyInput } from '../currency-input'

import {
  formattingValues,
  determineFormValue,
  sendToDataLayer,
  isMinimum,
  getDonationOptions
} from '../../util/index.js'
import { usePayment } from '../../hooks/use-payment'
import { formatCurrency } from '../../util'
import { useCurrencyRate } from '../../hooks/use-currency-rates'

const donationIds = ['low', 'mid', 'high']

export const UnrestrictedForm = ({
  onPaymentError,
  onPaymentSuccess,
  supportsApplePay
}) => {
  const {
    donationAmount,
    donationCurrency,
    locale,
    userLocation,
    actions: { setDonationAmount, setPaymentType }
  } = usePayment()

  useEffect(() => {
    sendToDataLayer(locale, userLocation, 'Donate', 'Unrestricted')
  }, [locale, userLocation])

  const [rate] = useCurrencyRate(donationCurrency)

  const [customAmount, setCustomAmount] = React.useState('')
  const nullRadio = React.createRef()
  const resetCheckboxes = () => {
    nullRadio.current.checked = true
  }

  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false)
  const formRef = React.createRef()
  const donationOptions = getDonationOptions(
    donationCurrency,
    userLocation.countryCode
  )

  const correctCustomAmount = e => {
    let asNumber = parseFloat(customAmount)

    // Handle empty field state
    if (isNaN(asNumber)) {
      setCustomAmount('')
    } else {
      setCustomAmount(parseFloat(customAmount).toFixed(2))
    }
  }

  // function added for Apple Pay button
  function handleDonationOptionChange(inputValue) {
    const value = inputValue || determineFormValue(formRef)
    if (value && isMinimum(value, rate)) {
      setDonationAmount(value)
    }
  }

  function handleDonationOptionClick(e) {
    const val = parseInt(e.target.value)

    if (val === donationAmount) {
      nullRadio.current.checked = true
      setDonationAmount(0)
    } else {
      setDonationAmount(val)
    }
  }

  function handlePaymentSubmit(paymentType) {
    const value = determineFormValue(formRef) ?? customAmount

    if (value && isMinimum(value, rate)) {
      if (paymentType) {
        setPaymentType(paymentType)
        sendToDataLayer(
          locale,
          userLocation,
          'Payment Method Selected',
          paymentType
        )
      }
      setDonationAmount(value)

      return Promise.resolve()
    }

    setIsFormSubmitted(true)
    return Promise.reject()
  }

  // Pressing 'ENTER' triggers a payment submission
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handlePaymentSubmit('creditCard')
    }
  }

  return (
    <>
      <Header viewScreen={'Payment method'} />
      <Body>
        <div className="ciicc-modal__wrap">
          <h2 className="ciicc-modal__title text -uppercase -bold">
            <FormattedMessage id="checkout.unrestricted.title" />
          </h2>
          <p className="ciicc-modal__copy">
            <FormattedMessage
              id="checkout.unrestricted.copy"
              values={formattingValues}
            />
          </p>

          <form ref={formRef} onKeyPress={handleKeyPress}>
            <div className="ciicc-modal__inputs">
              <div className="ciicc-modal__inputs__radios">
                {donationOptions.map((d, i) => (
                  <DonationOption
                    amount={formatCurrency(d, locale, userLocation)}
                    id={donationIds[i]}
                    value={d}
                    key={d}
                    onInputChange={
                      () => handleDonationOptionChange() /* omit event arg */
                    }
                    onClick={handleDonationOptionClick}
                  />
                ))}

                <label
                  aria-hidden="true"
                  className="screenreader-only"
                  htmlFor="donation-amount"
                >
                  <input
                    id="donation-amount"
                    type="radio"
                    name="donationAmount"
                    ref={nullRadio}
                  />
                </label>
              </div>
              <div className="ciicc-labeled-input">
                <label htmlFor="unrestricted">
                  <FormattedMessage id="checkout.unrestricted.input.label" />
                </label>
                <CurrencyInput
                  value={customAmount}
                  onClick={resetCheckboxes}
                  onBlur={correctCustomAmount}
                  onChange={e => {
                    let value = e.target.value.replace(/[^0-9.]+/g, '')

                    setCustomAmount(value)
                    handleDonationOptionChange(value)
                    resetCheckboxes()
                  }}
                />
              </div>
            </div>

            <div className="ciicc-modal__error">
              {isFormSubmitted && !isMinimum(donationAmount, rate) && (
                <p className="text -bold centered-text" role="alert">
                  <FormattedMessage id="checkout.error.minimum_donation" />
                </p>
              )}
            </div>

            <PaymentChoices
              onPaymentError={onPaymentError}
              onPaymentSuccess={onPaymentSuccess}
              onSubmit={handlePaymentSubmit}
              supportsApplePay={supportsApplePay}
            />
          </form>
        </div>
      </Body>
    </>
  )
}

const DonationOption = ({ id, amount, value, onInputChange, onClick }) => (
  <div className="ciicc-modal__inputs__radio">
    <input
      id={id}
      type="radio"
      className="ciicc-input"
      name="donationAmount"
      value={value}
      onChange={onInputChange}
      onClick={onClick}
    />
    <label htmlFor={id}>{amount}</label>
  </div>
)
