import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { formattingValues } from '../util'
import { CALCULATOR_TYPE } from '../calculator/constants'

import close from '../assets/images/close.png'

export const LandingProjects = injectIntl(({ intl, setCalcType }) => {
  // set active project to 0 since first project starts at 1
  const [activeIndex, setActive] = useState(0)

  const projectImageCaptions = ['© Charlie Shoemaker', '© Ami Vitale']

  const generateProjects = () => {
    return projectImageCaptions.map((caption, index) => (
      <div
        className={`ciicc-landing-project${
          activeIndex === index + 1 ? ' -active' : ''
        }`}
        key={`project-${index + 1}`}
      >
        <div className="ciicc-landing-project__front-panel">
          <h3 className="ciicc-landing-project__heading text -bold-condensed">
            <FormattedMessage id={`home.project${index + 1}.title`} />
            <span>
              <FormattedMessage id={`home.project${index + 1}.subtitle`} />
            </span>
          </h3>
          <button
            className="ciicc-button -blue"
            tabIndex={activeIndex === index + 1 ? -1 : 0}
            onClick={() => setActive(index + 1)}
          >
            <FormattedMessage
              id={`home.project${index + 1}.learn_button.text`}
            />
          </button>
          <p className="ciicc-landing-project__image-caption">{caption}</p>
        </div>
        <div className="ciicc-landing-projects__back-panel">
          <div className="ciicc-landing-projects__group">
            <button
              className="ciicc-landing-projects__close unset-button"
              tabIndex={activeIndex === index + 1 ? 0 : -1}
              onClick={() => setActive(0)}
            >
              <span className="screenreader-only">Close</span>
              <img src={close} alt="" />
            </button>
            <p className="ciicc-landing-project__subheading text -bold-condensed">
              <FormattedMessage id={`home.project${index + 1}.full_title`} />{' '}
              
            </p>
            <p className="ciicc-rtf">
              <FormattedMessage
                id={`home.project${index + 1}.copy`}
                values={formattingValues}
              />
            </p>
          </div>
          <div className="ciicc-landing-projects__group">
            <p className="centered-text">
              <b>
                <FormattedMessage
                  id={`home.project${index + 1}.emphasized_copy`}
                  values={formattingValues}
                />
              </b>
            </p>

            <Link
              to={{
                pathname: `/${intl.locale}/questionnaire`
              }}
              onClick={() => setCalcType(CALCULATOR_TYPE.individual)}
              tabIndex={activeIndex === index + 1 ? 0 : -1}
              className="ciicc-button -blue"
            >
              <FormattedMessage id="home.projects.start_calculator" />
            </Link>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <section className="ciicc-section">
      <div className="ciicc-wrap">
        <div className="ciicc-section-heading">
          <h2 className="heading -secondary text -bold-condensed -center">
            <FormattedMessage id="home.projects.title" />
          </h2>
          <div className="ciicc-wrap -xnarrow">
          <p className=" ciicc-wrap ciicc-rtf">
            <FormattedMessage
              id="home.projects.copy"
              values={formattingValues}
            />
          </p>
          </div>
        </div>
        <div className="ciicc-landing-projects">
          {generateProjects(setActive, activeIndex)}
        </div>
      </div>
    </section>
  )
})
