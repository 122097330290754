/**
 * Calculator Data Table
 * Singapore
 */
export const SINGAPORE = {
  continent: 'AS',
  energy: {
    A: 0.15,
    B: 1.92,
    C: 5.46,
    D: 7.24,
    E: 7.24
  },
  diet: {
    A: 2.41,
    B: 1.85,
    C: 1.47,
    D: 0.97,
    E: 0.54
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 4,
  average: {
    annualFootprint: {
      individual: 11.35,
      household: 45.38
    },
    housingAndDiet: {
      individual: 2.59,
      household: 8.54
    },
    groundTransportation: {
      individual: 0.7,
      household: 1.73
    },
    airTravel: {
      individual: 1.26,
      household: 2.27
    }
  }
}
