import axios from 'axios'
import rates from './currency-exchange-rates.json'

const exchangeClient = axios.create({
  baseURL: 'https://exchangerates.car-calc.cc/latest.json'
})

export const getExchangeRates = async () => {
  // During dev mode we'll just use a static JSON
  if (process.env.NODE_ENV === 'development') {
    return rates
  }

  try {
    const response = await exchangeClient.get()

    return response.data
  } catch (error) {
    // @TODO (prayash): Ask CI about failure recovery here.
    console.log(error)
    return rates
  }
}
