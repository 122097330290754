import { INTERNATIONAL } from './international'
import { NORTH_CENTRAL_AMERICA } from './north-central-america/continental'
import { SOUTH_AMERICA } from './south-america/continental'
import { EUROPE } from './europe/continental'
import { ASIA } from './asia/continental'
import { AFRICA } from './africa/continental'
import { AUSTRALIA } from './australia/continental'

/**
 * These values represent the Q1, Q2, and Q4 factors for the HOME component of the footprint.
 * They DO NOT vary by region!
 */
export const UTILITY_FACTORS = {
  type: {
    A: 1.0,
    B: 0.73,
    C: 0.65,
    D: 0.42
  },
  size: {
    A: 0.8,
    B: 0.76,
    C: 0.8,
    D: 0.97,
    E: 1.08,
    F: 1.15,
    G: 1.25,
    H: 1.44
  },
  residents: {
    1: 1.89,
    2: 1.3,
    3: 0.94,
    4: 0.76,
    5: 0.64,
    6: 0.48
  }
}

/**
 * These values hold the various factors that get used in calculating different
 * components of the final carbon footprint.
 * They are highly specific to each region.
 */
export const REGIONAL_FACTORS = {
  INTL: INTERNATIONAL,

  // Continents
  NA: NORTH_CENTRAL_AMERICA,
  EU: EUROPE,
  AS: ASIA,
  AF: AFRICA,
  OC: AUSTRALIA,
  SA: SOUTH_AMERICA,

  // Target Jurisdictions
  US: NORTH_CENTRAL_AMERICA.countries.USA,
  CA: NORTH_CENTRAL_AMERICA.countries.CANADA,
  MX: NORTH_CENTRAL_AMERICA.countries.MEXICO,
  AU: AUSTRALIA,
  GB: EUROPE.countries.BRITAIN,
  FR: EUROPE.countries.FRANCE,
  PT: EUROPE.countries.PORTUGAL,
  DE: EUROPE.countries.GERMANY,
  ES: EUROPE.countries.SPAIN,
  BR: SOUTH_AMERICA.countries.BRAZIL,
  AR: SOUTH_AMERICA.countries.ARGENTINA,
  CO: SOUTH_AMERICA.countries.COLOMBIA,
  KR: ASIA.countries.SOUTH_KOREA,
  NZ: ASIA.countries.NEW_ZEALAND,
  IN: ASIA.countries.INDIA,
  JP: ASIA.countries.JAPAN,
  SG: ASIA.countries.SINGAPORE
}

/**
 * An enum for representing the two different calculator types.
 */
export const CALCULATOR_TYPE = {
  individual: 'individual',
  household: 'household'
}

const DEFAULT_HOUSEHOLD_FACTORS = {
  qType: 'C',
  qSize: 'D',
  qEmissions: 'E',
  qResidents: '2',
  qDiet: 'B'
}

const DEFAULT_TRANPORT_FACTORS = {
  qAboveGround: undefined,
  qBelowGround: undefined,
  qBus: undefined,
  qVehicles: [],
  qAirDistance: undefined
}

export const DEFAULT_FACTORS = {
  household: DEFAULT_HOUSEHOLD_FACTORS,
  transport: DEFAULT_TRANPORT_FACTORS
}

export const USD_PER_TONNE = 21
