// Polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

// Polyfill for FormData used in checkout flow
import 'formdata-polyfill'

import 'what-input'
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/styles/index.scss'
import AppContainer from './app'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<AppContainer />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
