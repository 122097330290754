export const CONFIG = {
  DONATION_MINIMUM: 3.0,
  PROCESSING_PERCENTAGE: 0.022,
  SHOULD_USE_DONORBOX: false, // set to true to fall back to donorbox links for donation processing
  LANDING_DONATE_BUTTON: true,
  QUESTIONNAIRE_DONATE_BUTTON: false,
  RESULTS_DONATE_BUTTON: false
}

/**
 * react-intl based config objects for use with the custom
 * currency input in the donation flow.
 */
export const CURRENCY_CONFIG = {
  formats: {
    number: {
      ARS: {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      AUD: {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      CAD: {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      COP: {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      EUR: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      GBP: {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },

      // !JPY and KRW are zero-decimal currencies!
      JPY: {
        style: 'currency',
        currency: 'JPY',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      },
      KRW: {
        style: 'currency',
        currency: 'KRW',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      },
      MXN: {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      SGD: {
        style: 'currency',
        currency: 'SGD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      USD: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
}

// unrestricted metadata values
export const DONATION_DESCRIPTION_UNR = 'FY20_General_Web_Top Nav-Button_UR'
export const DONATION_CAMPAIGN_UNR = '20 General Web'
export const DONATION_RE_CAMPAIGN_UNR = '20 AG UNRE'

// restricted metadata values
export const DONATION_DESCRIPTION_R =
  'FY20_Carbon Calculator_Web_Results Page_R'
export const DONATION_CAMPAIGN_R = 'CarbonCal General'
export const DONATION_RE_CAMPAIGN_R = '20 AG RES'
