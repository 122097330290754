import queryString from 'query-string'
import { REGIONAL_FACTORS } from './constants'
import { TARGET_JURISDICTIONS, BLACKLIST } from '../constants/countries'
import { round } from '../util'

/**
 * Base calculator object for other calculators to inherit from / override.
 */
export class BaseCalculator {
  constructor({ countryCode, continentCode }) {
    if (!countryCode || !continentCode) {
      throw new Error('You must pass in a valid region.')
    }

    // Blacklisted or unsupported countries will default to the continent they're in
    if (BLACKLIST.has(countryCode) || !TARGET_JURISDICTIONS.has(countryCode)) {
      this.region = continentCode
    } else {
      this.region = countryCode
    }
  }

  /**
   * Q1 * [row 26] * 52 + Q2 * [row 27] * 52 + Q3 * [row 28] * 52
   * @param {Object} transportFactors (see `DEFAULT_TRANPORT_FACTORS`)
   */
  transitFootprint({ qAboveGround, qBelowGround, qBus }) {
    const { region } = this
    const qAboveGroundValue = isFinite(qAboveGround) ? qAboveGround : 0
    const qBelowGroundValue = isFinite(qBelowGround) ? qBelowGround : 0
    const qBusValue = isFinite(qBus) ? qBus : 0

    const aboveGround =
      qAboveGroundValue * REGIONAL_FACTORS[region].transit.above
    const belowGround =
      qBelowGroundValue * REGIONAL_FACTORS[region].transit.below
    const bus = qBusValue * REGIONAL_FACTORS[region].transit.bus
    return round(aboveGround * 52 + belowGround * 52 + bus * 52)
  }

  /**
   * Calculates the total travel footprint.
   * @param {Object} transportFactors (see `DEFAULT_TRANPORT_FACTORS`)
   */
  travelFootprint({ transport }) {
    return round(
      this.transitFootprint(transport) +
        this.drivingFootprint(transport) +
        this.airFootprint(transport)
    )
  }

  /**
   * Q1 * CO2 factor (air)
   * @param {Object} transportFactors (see `DEFAULT_TRANPORT_FACTORS`)
   */
  airFootprint({ qAirDistance }) {
    const { region } = this
    const airFactor = REGIONAL_FACTORS[region].transportation.air
    const qAirDistanceValue = isFinite(qAirDistance) ? qAirDistance : 0

    return round(qAirDistanceValue * airFactor)
  }

  /**
   * Each vehicle -> Q2 / Q3 * CO2 factor * Q1
   * @param {Array<Vehicle>} qVehicles
   */
  drivingFootprint({ qVehicles }) {
    const { region } = this
    const total = qVehicles.reduce((sum, vehicle) => {
      let { qVehicleType, qAnnualMileage, qFuelEconomy } = vehicle
      let fuel = REGIONAL_FACTORS[region].transportation.driving[qVehicleType]
      let vehicleFootprint = (qAnnualMileage / qFuelEconomy) * fuel

      return sum + vehicleFootprint
    }, 0)

    const drivingCalc = round(total)
    return isNaN(drivingCalc) ? 0 : drivingCalc
  }

  /**
   * Calculates the total individual footprint (in metric tons CO^2 per year).
   * 5 components: home + driving + travel + public transit + goods/services
   * @param {Object} factors
   */
  calculate({ household, transport }) {
    return round(
      this.homeFootprint(household) +
        this.transitFootprint(transport) +
        this.drivingFootprint(transport) +
        this.airFootprint(transport) +
        REGIONAL_FACTORS[this.region].goods
    )
  }

  /**
   * Serializes the calculator's results into query params.
   * http://localhost:3000/en-us/results?a=0.4&c=individual&ci=12.14&h=3.06&r=US&t=3.68
   */
  serialize({ household, transport }) {
    return queryString.stringify({
      ci: this.calculate({ household, transport }),
      h: this.homeFootprint(household),
      t: round(
        this.transitFootprint(transport) + this.drivingFootprint(transport)
      ),
      a: this.airFootprint(transport),
      r: this.region,
      c: this.type
    })
  }
}
