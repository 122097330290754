import React, { useReducer } from 'react'

import { StripeElements } from '../payment/stripe-elements'
import { DonationPaymentProvider } from './payment-container'

const donationModalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, isOpen: true }

    case 'CLOSE_MODAL':
      return { ...state, isOpen: false }

    default:
      break
  }
  return state
}

export const DonationModalContext = React.createContext(null)

export const DonationModalProvider = ({ children, locale, userLocation }) => {
  const initialState = {
    isOpen: false
  }
  const [state, dispatch] = useReducer(donationModalReducer, initialState)

  const localeLanguage = locale.split('-')[0]

  return (
    <StripeElements options={{ locale: localeLanguage }}>
      <DonationModalContext.Provider value={[state, dispatch]}>
        <DonationPaymentProvider locale={locale} userLocation={userLocation}>
          {children}
        </DonationPaymentProvider>
      </DonationModalContext.Provider>
    </StripeElements>
  )
}
