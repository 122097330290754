import React, { useEffect } from 'react'
import queryString from 'query-string'
import isEmpty from 'lodash.isempty'
import { Redirect } from 'react-router-dom'
import { PageContainer } from '../components/page-container'
import { ResultsHero } from '../components/results-hero'
import { ResultsComparison } from '../components/results-comparison'
import { ResultsSignup } from '../components/results-signup'
import { ResultsTips } from '../components/results-tips'
import { ResultsProjects } from '../components/results-projects'
import { LandingHero } from '../components/landing-hero'
import { ResultsMore } from '../components/results-more'
import { sendToDataLayer, isRussia } from '../util'

function Results({ location, userLocation, locale }) {
  useEffect(() => {
    // send user progress to GTM
    sendToDataLayer(locale, userLocation, '4 - Results Loaded')
  }, [locale, userLocation])

  const shouldShowEmailSignup = !isRussia(userLocation.countryCode)
  const deserialized = queryString.parse(location.search)

  // Prevent the results page from being loaded without valid query params
  if (isEmpty(deserialized)) {
    return <Redirect to="/" />
  }

  const {
    r: region,
    c: type,
    ci: carbonImpact,
    h: householdAndDiet,
    a: airTravel,
    t: transportation
  } = deserialized

  return (
    <PageContainer>
      <ResultsHero
        carbonImpact={carbonImpact}
        region={region}
        locale={locale}
        userLocation={userLocation}
      />

      <ResultsComparison
        carbonImpact={carbonImpact}
        airTravel={airTravel}
        transportation={transportation}
        householdAndDiet={householdAndDiet}
        region={region}
        type={type}
      />
      {shouldShowEmailSignup && <ResultsSignup tonnage={carbonImpact} />}
      <ResultsTips />
      <ResultsProjects />
      <ResultsMore />
      <LandingHero
        title={'home.intro.heading'}
        copy={'home.intro.copy'}
      />
    </PageContainer>
  )
}

export default Results
