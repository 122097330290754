/**
 * Calculator Data Table
 * Brazil
 */
export const BRAZIL = {
  continent: 'SA',
  energy: {
    A: 0.04,
    B: 0.47,
    C: 1.35,
    D: 1.78,
    E: 1.14
  },
  diet: {
    A: 1.84,
    B: 1.42,
    C: 1.13,
    D: 0.75,
    E: 0.41
  },
  transit: {
    above: 0.000005,
    below: 0.000005,
    bus: 0.000052
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 1,
  average: {
    annualFootprint: {
      individual: 2.28,
      household: 9.1
    },
    housingAndDiet: {
      individual: 1.68,
      household: 5.55
    },
    groundTransportation: {
      individual: 0.5,
      household: 1.24
    },
    airTravel: {
      individual: 0.1,
      household: 0.19
    }
  }
}
