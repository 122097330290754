/**
 * Calculator Data Table
 * Spain
 */
export const SPAIN = {
  continent: 'EU',
  energy: {
    A: 0.07,
    B: 0.86,
    C: 2.45,
    D: 3.24,
    E: 2.38
  },
  diet: {
    A: 2.44,
    B: 1.88,
    C: 1.49,
    D: 0.99,
    E: 0.55
  },
  transit: {
    above: 0.000028,
    below: 0.00003,
    bus: 0.00024
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 3,
  average: {
    annualFootprint: {
      individual: 6.07,
      household: 24.28
    },
    housingAndDiet: {
      individual: 2.36,
      household: 6.13
    },
    groundTransportation: {
      individual: 0.54,
      household: 1.05
    },
    airTravel: {
      individual: 0.27,
      household: 0.49
    }
  }
}
