/**
 * Calculator Data Table
 * Africa
 */
export const AFRICA = {
  continent: 'AF',
  energy: {
    A: 0.04,
    B: 0.48,
    C: 1.35,
    D: 1.79,
    E: 1.65
  },
  diet: {
    A: 0.81,
    B: 0.63,
    C: 0.5,
    D: 0.33,
    E: 0.18
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0
    },
    air: 0.0001
  },
  goods: 0.5,
  average: {
    annualFootprint: {
      individual: 1.13,
      household: 4.52
    },
    housingAndDiet: {
      individual: 0.91,
      household: 4.34
    },
    groundTransportation: {
      individual: 0.12,
      household: 0.43
    },
    airTravel: {
      individual: 0.01,
      household: 0.03
    }
  }
}
