import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'

import enMetaImage from '../../assets/images/en-share-image.jpg'
import deMetaImage from '../../assets/images/de-share-image.jpg'
import esMetaImage from '../../assets/images/es-share-image.jpg'
import frMetaImage from '../../assets/images/fr-share-image.jpg'
import ptMetaImage from '../../assets/images/pt-share-image.jpg'
import jpMetaImage from '../../assets/images/jp-share-image.jpg'
import krMetaImage from '../../assets/images/kr-share-image.jpg'

const localizedImages = {
  'de-de': deMetaImage,
  'en-gb': enMetaImage,
  'en-us': enMetaImage,
  'es-es': esMetaImage,
  'es-la': esMetaImage,
  'fr-fr': frMetaImage,
  'ja-jp': jpMetaImage,
  'ko-kr': krMetaImage,
  'pt-br': ptMetaImage
}

export const ShareMeta = injectIntl(({ intl, pageKey, locale }) => {
  const stringKey = `meta.share.${pageKey}`
  const shareImage = localizedImages[locale]

  // Social media image paths must be absolute HTTPS URLs.
  const shareImageAbsoluteUrl = `${window.location.origin}${shareImage}`

  return (
    <Helmet>
      <title>{intl.formatMessage({ id: `${stringKey}.title` })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: `${stringKey}.description` })}
      />

      <meta
        property="og:site_name"
        content={intl.formatMessage({ id: 'home.title' })}
      />
      <meta
        name="twitter:title"
        content={intl.formatMessage({
          id: `${stringKey}.title`
        })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({
          id: `${stringKey}.description`
        })}
      />
      <meta name="twitter:image:src" content={shareImageAbsoluteUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ConservationOrg" />

      <meta
        property="og:title"
        content={intl.formatMessage({
          id: `${stringKey}.title`
        })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({
          id: `${stringKey}.description`
        })}
      />
      <meta property="og:image" content={shareImageAbsoluteUrl} />
    </Helmet>
  )
})
