import React from 'react'
import { FormattedMessage } from 'react-intl'
//import projectImage1 from '../assets/images/results-project1.jpg'
import projectImage2 from '../assets/images/results-project2.jpg'
import projectImage3 from '../assets/images/results-project3.jpg'

export const ResultsProjects = () => {
  const generateProjects = () => {
    // generate array of project images
    const projectImages = [
      //projectImage1,
      projectImage2,
      projectImage3
    ]
  
    return projectImages.map((image, index) => (
      <div
        className="ciicc-results-project"
        key={`results-project-${index}`}
      >
        <img src={image} alt="" />
        <h3 className="heading -tertiary text -bold">
          <FormattedMessage
            id={`results.how.project${index + 2}.title`}
          />
        </h3>
        <p className="text">
          <FormattedMessage
            id={`results.how.project${index + 2}.copy`}
          />
        </p>
      </div>
    ))
  }
  
  return (
    <section className="ciicc-section">
      <div className="ciicc-wrap">
        <div className="ciicc-section-heading">
          <h2 className="heading -secondary text -bold-condensed -center">
            <FormattedMessage id="results.how.title" />
          </h2>
          <p className="centered-text">
            <FormattedMessage id="results.how.copy" />   
          </p>
        </div>
        <div className="ciicc-results-projects layout-grid -wide">
          {generateProjects()}
        </div>
      </div>
    </section>
  )
}