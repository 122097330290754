import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CONFIG } from '../config'
import { BLACKLIST } from '../constants/countries'
import { getDonorboxUrl } from '../constants/donorbox'
import { getFundraiseupUrl } from '../constants/fundraiseup'
import { getUserLocation } from '../services/location'
import { usePayment } from '../hooks/use-payment'
import { useCurrencyRate } from '../hooks/use-currency-rates'
import { round } from '../util'

export const DonateButton = ({
  id,
  className = '',
  onClick,
  onMount = function() {}
}) => {
  const [isBlacklisted, setIsBlacklisted] = React.useState(true)

  React.useEffect(() => {
    // Sneaky users can change the userLocation record in localStorage
    // in order to trick the app, so we double-confirm by fetching IP location
    // for each donation button
    const verifyEligibility = async () => {
      const { countryCode } = await getUserLocation()

      setIsBlacklisted(BLACKLIST.has(countryCode))
    }

    verifyEligibility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let DonateButtonComponent = Button
  if (CONFIG.SHOULD_USE_DONORBOX) {
    DonateButtonComponent = DonorboxButton
  }
  else
  {
    DonateButtonComponent = FundraiseupButton
  }
  const buttonClassName = `ciicc-button -red ${className}`

  return isBlacklisted ? null : (
    <DonateButtonComponent className={buttonClassName} onClick={onClick}>
      <FormattedMessage id={id} />
    </DonateButtonComponent>
  )
}

const Button = ({ className, onClick, children }) => (
  <button className={className} onClick={onClick}>
    {children}
  </button>
)

// this is actually an external link, but it still looks like a button
export const DonorboxButton = ({ className, onClick, children }) => {
  const {
    locale,
    donationCurrency,
    totalDonationAmount,
    userLocation: { countryCode }
  } = usePayment()

  const [usdRate] = useCurrencyRate(donationCurrency)
  let donorBoxAmount = totalDonationAmount
  if (usdRate && usdRate > 0) {
    // ensure donorbox amount is converted to USD per spec
    //donorBoxAmount = round(donorBoxAmount / usdRate)
    donorBoxAmount = donorBoxAmount
  }
  const url = getDonorboxUrl(
    {
      locale,
      countryCode
    },
    donorBoxAmount
  )

  return (
    <a
      href={url}
      className={className}
      rel="noopener noreferrer"
      title= "A"
      // onClick={e => {
      //   e.preventDefault()
      //   onClick({ shouldOpenModal: false })
      //   Object.assign(document.createElement('a'), {
      //     href: url,
      //     rel: 'noopener noreferrer'
      //   }).click()
      // }}
    >
      {children}
    </a>
  )
}

// this is actually an external link, but it still looks like a button
export const FundraiseupButton = ({ className, onClick, children }) => {
  const {
    locale,
    donationCurrency,
    totalDonationAmount,
    userLocation: { countryCode }
  } = usePayment()

  //console.warn(locale, donationCurrency, totalDonationAmount, countryCode)

  const [usdRate] = useCurrencyRate(donationCurrency)
  let fundraiseupAmount = totalDonationAmount
  if (usdRate && usdRate > 0) {
    // ensure donorbox amount is converted to USD per spec
    //fundraiseupAmount = round(fundraiseupAmount / usdRate)
    fundraiseupAmount = fundraiseupAmount
  }
  const url = getFundraiseupUrl(
    {
      donationCurrency,
      locale,
    },
    fundraiseupAmount
  )

  return (
    


    <a
      href={url}
      className={className}
      rel="noopener noreferrer"
      
      target='_blank'
       onClick={e => {
         e.preventDefault()
      //   onClick({ shouldOpenModal: false })
         Object.assign(document.createElement('a'), {
           href: url,
           rel: 'noopener noreferrer',
           target: '_blank'
         }).click()
       }}
    >
      {children}
    </a>
  )
}