import React from 'react'
import { FormattedMessage } from 'react-intl'

export const NumericInput = ({
  id,
  message,
  value,
  onChange,
  unit = null,
  required = false,
  pattern = '[0-9]+'
}) => (
  <div className="ciicc-questionnaire__content__input">
    <label htmlFor={id}>
      <FormattedMessage id={message} /> {unit && <FormattedMessage id={unit} />}
    </label>
    <input
      id={id}
      name={id}
      type="text"
      pattern={pattern}
      value={!value && value !== 0 ? '' : value}
      className="ciicc-input"
      onChange={onChange}
      required={required}
      onInvalid={e => e.currentTarget.classList.add('-invalid')}
    />
  </div>
)
