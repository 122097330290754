import { BRAZIL } from './brazil'
import { ARGENTINA } from './argentina'
import { COLOMBIA } from './colombia'

/**
 * Calculator Data Table
 * South America
 */
export const SOUTH_AMERICA = {
  continent: 'SA',
  energy: {
    A: 0.05,
    B: 0.5,
    C: 1.41,
    D: 1.86,
    E: 1.41
  },
  diet: {
    A: 1.9,
    B: 1.46,
    C: 1.16,
    D: 0.77,
    E: 0.43
  },
  transit: {
    above: 0.000115,
    below: 0.000101,
    bus: 0.000069
  },
  transportation: {
    driving: {
      gas: 0.0023,
      diesel: 0.0027,
      electric: 0.0
    },
    air: 0.0001
  },
  goods: 1,
  average: {
    annualFootprint: {
      individual: 2.69,
      household: 10.76
    },
    housingAndDiet: {
      individual: 1.78,
      household: 6.04
    },
    groundTransportation: {
      individual: 0.5,
      household: 1.28
    },
    airTravel: {
      individual: 0.1,
      household: 0.28
    }
  },
  countries: {
    BRAZIL,
    ARGENTINA,
    COLOMBIA
  }
}
